import VueCookies from 'vue-cookies'

export const REGULAR_TOKEN = 'regular_token'
export const REMEMBER_TOKEN = 'remember_token'

import { cookieExpireTime } from '@/constants'

export class TokenClass {


  static get regularToken () {
    return sessionStorage.getItem(`${REGULAR_TOKEN}`)
  }
  static setRegularToken (token) {
    sessionStorage.setItem(`${REGULAR_TOKEN}`, token)
  }

  static setRememberToken (token) {
    VueCookies.set(REMEMBER_TOKEN, token, cookieExpireTime, null, null, false, 'Lax')
  }

  static removeRegularToken () {
    sessionStorage.removeItem(`${REGULAR_TOKEN}`)
  }

  static removeRememberToken () {
    VueCookies.remove(REMEMBER_TOKEN)
  }

  static get isAuthenticated () {
    return this.regularToken != null || VueCookies.get(REMEMBER_TOKEN) != null
  }

  static get isNotAuthenticated () {
    return  this.regularToken === null && VueCookies.get(REMEMBER_TOKEN) === null
  }

  static get userToken () {
    return this.regularToken || VueCookies.get(REMEMBER_TOKEN)
  }

}