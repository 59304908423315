<template>
  <div>
    <v-container class="list-container">
      <list-overview
        :can-create=true
        :cols-prop="{ cols: 12, sm: 12, md: 6, lg: 4, xl: 4 }"
        :data-source="productsProp"
        :is-list-loading-prop="isListLoadingProp"
        :selectable="true"
        list-type="product"
      >
        <template #entity-card="{ entity, selectable }">
          <list-item-common-product
            :class="highlightSelectedProductClass(entity.id)"
            :entity="entity"
            :selectable="selectable"
            class="cursor-pointer each-product"
            @click.native="$emit('selectProduct', entity)"
            @changeLoading="changeLoading"
            @setUpdatingProduct="setUpdatingProduct"
            :key="keyRerender"
          />
        </template>
      </list-overview>
    </v-container>
  </div>
</template>

<script>
import ListOverview from '@/components/list/ListOverview'
import ListItemCommonProduct from '@/components/list/ListItemCommonProduct'

import { v4 as uuidv4 } from 'uuid'
export default {
  name: "ProductListOverview",
  components: { ListItemCommonProduct, ListOverview },
  props: {
    productsProp: {
      type: Array,
      required: true
    },
    isListLoadingProp: {
      type: Boolean,
      required: true
    },
    selectedProductProp: {
      type: Object,
      default: null
    }
  },

  data () {
    return {
      products: this.productsProp,

      updateFinished: false,

      loading: false,

      currentlyUpdatingProduct: null,

      keyRerender: uuidv4(),

    }
  },

  computed: {
    selectedOrganization () {
      return this.$store.getters['organization/getSelectedOrganization']
    },
  },

  methods: {
    highlightSelectedProductClass (entityId) {
      if (this.selectedProductProp && this.selectedProductProp.id) {
        return (entityId === this.selectedProductProp.id) ? 'active-item' : 'inactive-item'
      }
    },

    async setUpdatingProduct (product) {
      this.currentlyUpdatingProduct = product
      await this.$store.dispatch('product/updateAnyProductUpdatingLoading', true)
    },

    changeLoading (state) {
      this.$set(this.currentlyUpdatingProduct, '_loadingUpdate', state)
    },

    async productUpdateStatus () {
      this.$set(this.currentlyUpdatingProduct, '_updateFinished', true)
      this.$set(this.currentlyUpdatingProduct, '_loadingUpdate', false)

      await this.$store.dispatch('product/fetchAllProducts', this.selectedOrganization.slug)
      await this.$store.dispatch('product/removeSelectedProduct')
      this.keyRerender = uuidv4()

      await this.$store.dispatch('product/updateAnyProductUpdatingLoading', false)

      await this.$store.dispatch('snackbar/showSnackbarMessage', {
        message: 'Success! The application has been updated',
        duration: 9000000,
        mode: 'success'
      })

    },

    async errorInstallProduct (error) {
      console.log(error, 'the error')
      await this.productUpdateStatus()

      await this.$store.dispatch('snackbar/showSnackbarMessage', {
        message: 'Error! The application has not been updated',
        duration: 9000000,
        mode: 'fail'
      })
    }
  },

  created () {
    window['productUpdateStatus'] = this.productUpdateStatus
    window['errorInstallProduct'] = this.errorInstallProduct
  },




}
</script>

<style lang="scss" scoped>
.active-item {
  border: 2px solid;
  border-color: var(--v-primary-base);
}

.inactive-item {
  border: 1px solid #707070;
  opacity: 0.2;
}

.list-container {
  width: 75%;
  @media only screen and (max-width: 1900px) {
    width: 100%;
  }
}
</style>
