<template>
  <v-card
    :ripple="{ class: 'primary--text' }"
    class="v-card--bordered"
    min-width="220px"
  >
    <div class="card-image">
      <v-img
        :src="entity.logo ? entity.logo.url : ''"
        class="mx-10"
        contain
        height="150"
      />
    </div>

    <v-divider />

    <v-card-subtitle>
      <div class="d-flex align-start">
        <div>
          <div class="text-h5 list-item-name">
            {{ entity.name }}
          </div>
        </div>
      </div>
    </v-card-subtitle>
  </v-card>
</template>

<script>
export default {
  name: 'ListItemCommon',

  props: {
    entity: {
      type: Object,
      required: true
    },

    selectable: {
      type: Boolean,
      required: false,
      default: false
    }
  }
}
</script>

<style lang="scss" scoped>
@import '~vuetify/src/styles/styles.sass';

.v-card {
  .card-image {
    background: var(--v-grey-lighten2);
  }

  &__subtitle {
    padding-right: 10px;
  }

  .select-checkbox {
    opacity: 0;
    visibility: hidden;
    transform: translateX(5px);
    transition: transform $secondary-transition,
    opacity $secondary-transition,
    visibility $secondary-transition;
  }

  .list-item-name {
    color: black;
    width: 170px;
    overflow: hidden;
    white-space: nowrap;
  }

  &:hover,
  &.selected {
    .select-checkbox {
      opacity: 1;
      visibility: visible;
      transform: translateX(0px);
    }

    .list-item-name {
      color: var(--v-primary-base)
    }
  }
}
</style>
