import OrganizationService from '@/services/оrganizations.service'

import { actionStates } from '@/constants'

const state = {
  currentState: actionStates.INITIAL,
  selectedOrganization: null,
  organizations: []
}

const getters = {
  getOrganizations (state) {
    return state.organizations
  },

  getCurrentState (state) {
    return state.currentState
  },

  getSelectedOrganization (state) {
    return state.selectedOrganization
  }
}

const mutations = {
  setActionState (state, actionState) {
    state.currentState = actionState
  },

  setOrganizations (state, organizations) {
    state.organizations = organizations ?? []
  },

  setSelectedOrganization (state, id) {
    state.selectedOrganization = id
  }
}

const actions = {
  async fetchAllOrganizations (vuexContext) {
    vuexContext.commit('setActionState', actionStates.LOADING)

    try {
      let response = await OrganizationService.getAllOrganizations()

      vuexContext.commit('setOrganizations', response)

      if (!response.length) {
        sessionStorage.removeItem('organization')
        vuexContext.commit('setSelectedOrganization', null)
      }

      vuexContext.commit('setActionState', actionStates.DATA_LOADED)
    } catch (e) {
      vuexContext.commit('setOrganizations', [])
      vuexContext.commit('setSelectedOrganization', null)

      if (e && e.response && e.response.status === 404) {
        vuexContext.commit('setActionState', actionStates.NOT_FOUND)
      } else {
        vuexContext.commit('setActionState', actionStates.ERRORED)
      }
    }
  },

  updateSelectedOrganization (vuexContext, payload) {
    let selectedOrganization = vuexContext.getters.getSelectedOrganization

    if (selectedOrganization && selectedOrganization.id === payload.id) {
      vuexContext.commit('setSelectedOrganization', null)
      return
    }
    vuexContext.commit('setSelectedOrganization', payload)
    sessionStorage.setItem('organization', JSON.stringify(payload))
  },

  removeSelectedOrganization (vuexContext) {
    vuexContext.commit('setSelectedOrganization', null)
  }
}

export { actionStates }

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions
}
