import $axios from '@/plugins/axios'

class ProductService {
  getAllProducts (slug) {
    const baseProductURL = `${ location.protocol }//${ slug }.${ process.env.VUE_APP_API_OMT_BASE_URL }/api/v1`

    return $axios.get(`${ baseProductURL }/get-products`)
      .then((res) => {
        return res.data
      })
      .catch((error) => {
        throw error
      })
  }

  getLauncherProducts () {
    const baseProductURL = `${ location.protocol }//${ process.env.VUE_APP_API_IMT_BASE_URL }/api/v1`

    return $axios.get(`${ baseProductURL }/get-launcher-products`)
      .then((res) => {
        return res.data
      })
      .catch((error) => {
        throw error
      })
  }


  getConfiguratorReleases(productId) {
    const baseAuthURL = `${ location.protocol }//${ process.env.VUE_APP_API_IMT_BASE_URL }/api/v1`

    return $axios.post(`${ baseAuthURL }/get-configurator-releases`, {
      "product_id": productId
    })
      .then((res) => {
        return res.data
      })
      .catch((error) => {
        throw error
      })
  }
}

export default new ProductService()
