<template>
  <div>
    <h3 class="mb-13 text-center text-h3 " style="color: #909090;">
     <div v-if="products.length">
       Select an application
     </div>

      <div v-else>
        No applications available
      </div>
    </h3>

    <product-step-overview-container />
  </div>
</template>

<script>

import ProductStepOverviewContainer from '@/components/product/ProductStepOverviewContainer'

export default {
  name: "ProductView",
  components: { ProductStepOverviewContainer },

  computed: {
    products () {
      return this.$store.getters['product/getProducts']
    }
  }
}
</script>

<style scoped>

</style>
