import ProjectService from '@/services/project.service'
import { actionStates } from '@/constants'

import Vue from 'vue'


const state = {
  currentState: actionStates.INITIAL,
  selectedConfiguration: null,
  configurations: []
}

const getters = {
  getConfigurations (state) {
    const filteredConf = state.configurations.filter(item => item.deleted_at === null)
    return filteredConf.sort((a, b) => new Date(b.updated_at) - new Date(a.updated_at))
  },

  getCurrentState (state) {
    return state.currentState
  },

  getSelectedConfiguration (state) {
    return state.selectedConfiguration
  }
}

const mutations = {
  setActionState (state, actionState) {
    state.currentState = actionState
  },

  setConfigurations (state, configurations) {
    state.configurations = configurations ?? []
  },

  setSelectedConfiguration (state, selectedConfiguration) {
    state.selectedConfiguration = selectedConfiguration
  },

  setSelectedConfigurationName (state, name) {
    Vue.set(state.selectedConfiguration, 'name', name)
  }
}

const actions = {
  async fetchAllConfigurations (vuexContext, data) {
    vuexContext.commit('setActionState', actionStates.LOADING)

    // console.log('REMOVE ')
    // data.api_domain = 'local.dreso-dev.link:8082'

    try {
      let response = await ProjectService.getProjectConfigurations(data.projectId, data.slug, data.api_domain)
      vuexContext.commit('setConfigurations', response)
      vuexContext.commit('setActionState', actionStates.DATA_LOADED)

      if (!response.length) {
        sessionStorage.removeItem('configuration')
        vuexContext.commit('setSelectedConfiguration', null)
      }

    } catch (e) {
      vuexContext.commit('setConfigurations', [])
      vuexContext.commit('setSelectedConfiguration', null)

      if (e && e.response && e.response.status === 404) {
        vuexContext.commit('setActionState', actionStates.NOT_FOUND)
      } else {
        vuexContext.commit('setActionState', actionStates.ERRORED)
      }
    }
  },

  updateSelectedConfiguration (vuexContext, payload) {
    vuexContext.commit('setSelectedConfiguration', payload)
    sessionStorage.setItem('configuration', JSON.stringify(payload))
  },

  removeSelectedConfiguration (vuexContext) {
    sessionStorage.removeItem('configuration')
    vuexContext.commit('setSelectedConfiguration', null)
  },

  setSelectedConfigurationName (vuexContext, confName) {
    vuexContext.commit('setSelectedConfigurationName', confName)
  },

  async createConfiguration (vuexContext, data) {
    try {
      await ProjectService.createConfiguration(data.projectId, data.configurationId, data.slug, data.api_domain)
    } catch (e) {
      vuexContext.commit('setSelectedConfiguration', null)
      throw e
    }
  },

  updateConfigurations (vuexContext, configurations) {
    vuexContext.commit('setConfigurations', configurations)
  }
}

export { actionStates }

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions
}
