<template>
  <div>
    <h3 class="mb-13 text-center text-h3" style="color: #909090;">

      <div v-if="organizations.length">
        Select an organization
      </div>

      <div v-else>
        No organizations available
      </div>

    </h3>

    <organization-step-overview-container />
  </div>
</template>

<script>
import OrganizationStepOverviewContainer from '@/components/organization/OrganizationStepOverviewContainer'

export default {
  name: "OrganizationView",
  components: { OrganizationStepOverviewContainer },

  computed: {
    organizations () {
      return this.$store.getters['organization/getOrganizations']
    }
  }


}
</script>

<style scoped>

</style>
