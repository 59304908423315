<template>
  <div class="text-center">
    <v-snackbar
      id="snackbar-message"
      v-model="showSnackbar"
      :multi-line="multiLine"
      :timeout="snackbarMessageData.duration"
      class="mb-15"
      :color=bgColor
      elevation="12"
    >
      {{ snackbarMessageData.message }}

      <template v-slot:action="{ attrs }">
        <v-btn
          id="snackbar-close-button"
          text
          v-bind="attrs"
          @click="closeSnackbar()"
        >
          Close
        </v-btn>
      </template>
    </v-snackbar>
  </div>
</template>

<script>
import { mapGetters } from "vuex"

export default {
  name: "AppSnackbar",

  data: () => ({
    multiLine: false,
    showSnackbar: false
  }),

  methods: {
    closeSnackbar () {
      this.showSnackbar = false
    }
  },

  computed: {
    ...mapGetters(
      {
        snackbarMessageData: 'snackbar/getSnackbarMessage'
      }
    ),

    bgColor () {
      const colorMap = [
        { name: 'success', value:'primary' },
        { name: 'fail', value: 'var(--v-error-base)' }
      ]

      const foundColor = colorMap.find(item => item.name === this.snackbarMessageData.mode)

      if (foundColor) {
        return foundColor.value
      }

      return 'primary'
    }
  },

  watch: {
    snackbarMessageData () {
      this.showSnackbar = true
    }
  }
}

</script>

<style lang="scss" scoped>

</style>
