<template>
  <div>
    <v-app-bar
        app
        class="pl-1 pr-3"
        clipped-left
        color="white"
        height="60px"
    >
      <v-img
          id="home-logo-button"
          :aspect-ratio="3.25"
          :src="getLogoImage()"
          contain
          max-height="30"
          max-width="88"
          v-bind="$attrs"
          v-on="$listeners"
      />

      <v-spacer/>

      <div class="d-flex align-center">
        <div class="header-btn cursor-pointer avatar">

          <v-tooltip bottom color="var(--v-primary-lighten2)">

            <template v-slot:activator="{ on, attrs }">

              <div class="text-center" v-bind="attrs" v-on="on">
                <v-menu offset-y bottom left nudge-bottom="5">

                  <template v-slot:activator="{ on, attrs }">
                    <v-avatar
                        size="36"
                        v-bind="attrs"
                        v-on="on"
                        class="avatar-menu"
                    >
                        <app-svg
                            v-if="!userImage"
                            alt="icon"
                            color="black"
                            size="20"
                            src="~/assets/img/icons/user-circle-black.svg"
                        />

                        <v-img v-else :src="userImage" />
                    </v-avatar>
                  </template>

                  <v-list class="user-avatar-menu">
                    <v-list-item class="v-list-height-20">
                      <v-list-item-content
                          style="font-weight: bold"
                      >
                        {{ userName }}
                      </v-list-item-content>
                    </v-list-item>

                    <v-list-item class="v-list-height-20">
                      <v-list-item-content>{{ userEmail }}</v-list-item-content>
                    </v-list-item>

                    <v-hover>
                      <template v-slot="{ hover }">
                        <v-list-item
                            class="mt-4"
                            :class="hover ? 'hovered-user-menu-item' : ''"
                            style="cursor: pointer"
                            @click="logout"
                        >
                          <v-list-item-content>Sign out</v-list-item-content>
                        </v-list-item>
                      </template>
                    </v-hover>
                  </v-list>

                </v-menu>
              </div>

            </template>

            <span class="tool-tip-content">User</span>

          </v-tooltip>

        </div>


<!--        Deyan Mihov requested to remove this functionality, because there is a close(x) button on entire browser-->
<!--        <div class="header-btn cursor-pointer pl-2 logout-btn">-->

<!--          <v-tooltip bottom color="var(&#45;&#45;v-primary-lighten2)">-->
<!--            <template v-slot:activator="{ on, attrs }">-->
<!--              <v-avatar-->
<!--                  size="36"-->
<!--                  v-bind="attrs"-->
<!--                  v-on="on"-->
<!--                  class="logout-btn-avatar"-->
<!--                  @click="quitClick"-->
<!--              >-->
<!--                <div class="icon">-->
<!--                  <app-svg-->
<!--                      alt="icon"-->
<!--                      color="black"-->
<!--                      size="20"-->
<!--                      src="~/assets/img/icons/power-off.svg"-->
<!--                  />-->
<!--                </div>-->
<!--              </v-avatar>-->
<!--            </template>-->
<!--            <span>Quit</span>-->
<!--          </v-tooltip>-->

<!--        </div>-->
      </div>
    </v-app-bar>

<!--    <app-quit-dialog-->
<!--        v-if="isQuitDialogVisible"-->
<!--        v-model="isQuitDialogVisible"-->
<!--    />-->

  </div>
</template>

<script>

import AppSvg from '@/components/app/AppSvg'
// import AppQuitDialog from '@/components/app/AppQuitDialog.vue'
import { mapGetters } from 'vuex'

export default {
  name: 'TheDefaultLayoutWrapAppBar',
  components: { AppSvg },

  // Deyan Mihov requested to remove this functionality, because there is a close(x) button on entire browser
  // data () {
  //   return {
  //     quitDialog: false
  //   }
  // },

  computed: {
    ...mapGetters(
        {
          currentUser: 'auth/getCurrentUser',
          // Deyan Mihov requested to remove this functionality, because there is a close(x) button on entire browser
          // isQuitDialogVisible: 'quitDialog/getIsQuitDialogVisible'

        }
    ),

    userName () {
      if (this.currentUser) {
        return this.currentUser.name
      }
      return null
    },

    userEmail () {
      if (this.currentUser) {
        return this.currentUser.email
      }
      return null
    },

    userImage () {
      if (this.currentUser && this.currentUser.image) {
        return this.currentUser.image.url
      }
      return null
    }
  },

  methods: {
    getLogoImage () {
      return require('@/assets/img/sandbox-logo.png')
    },

    // Deyan Mihov requested to remove this functionality, because there is a close(x) button on entire browser
    // quitClick () {
    //   // this.quitDialog = true
    //   this.$store.dispatch('quitDialog/updateIsQuitDialogVisible', true)
    // },

    async logout () {
      await this.$store.dispatch('auth/signOut')
      await this.$router.replace('/login')

      // There is a check(not to setting the same item) in the store while updating, selected org, product, project and conf
      await this.$store.dispatch('organization/removeSelectedOrganization')
      await this.$store.dispatch('product/removeSelectedProduct')
      await this.$store.dispatch('project/removeSelectedProject')
      await this.$store.dispatch('configuration/removeSelectedConfiguration')
      sessionStorage.clear()
    },
  }
}
</script>

<!--suppress CssInvalidPseudoSelector -->
<style lang="scss" scoped>
@import '~vuetify/src/styles/styles.sass';

:deep(.v-toolbar__content) {
  padding: 15px 25px;
}

.header-btn {
  user-select: none;
  color: var(--v-grey-base);
  border-radius: $border-radius-root;
  transition: background $secondary-transition,
  border $secondary-transition,
  color $secondary-transition;

  .v-avatar {
    background: var(--v-grey-lighten2);
    transition: background $secondary-transition;
  }

  &:hover {
    border-color: var(--v-primary-lighten2);
    color: var(--v-primary-lighten2);

    .v-avatar {
      background: var(--v-primary-lighten2);
    }
  }
}

.hovered-user-menu-item {
  background-color: var(--v-primary-lighten2);
}
</style>
