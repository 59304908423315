import { BaseClass } from '@/classes/base/BaseClass'

export class ConfigurationTemplateClass extends BaseClass {
  constructor (data = {}) {
    super()

    this.id = data.id
    this.name = data.name
    this.description = data.description
    this.screenshot_file = data.screenshot_file
    this.project_id = data.project_id
    this.data = data.data
    this.created_at = data.created_at
    this.updated_at = data.updated_at
  }

  get image () {
    return this.screenshot_file.url
  }
}