<template>
  <v-sheet
      color="grey lighten-4"
      v-bind="$attrs"
      v-on="$listeners"
  >
    <v-skeleton-loader
        class="mx-auto"
        type="list-item-avatar-two-line@4"
    />
  </v-sheet>
</template>


<script>
export default {
  name: "AppLoaderListing"
}
</script>

<style scoped>

</style>
