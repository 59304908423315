<template>
  <v-card
    :ripple="{ class: 'primary--text' }"
    class="v-card--bordered"
    min-width="220px"
  >
  <div >
    <v-divider />
    <v-img
        :src="getImage"
        height="120"
        contain
    />
  </div>

    <v-divider />

    <v-card-subtitle class="py-2 px-2">
      <div class="d-flex align-start">
        <div>
          <div class="text-h5 list-item-name">
            {{ entity.name }}
          </div>

          <div class="text-h5 list-item-name">
          </div>
        </div>

        <v-spacer></v-spacer>

        <v-img
          id="entity-logo"
          :src="entity.logo ? entity.logo.url : ''"
          contain
          max-height="35"
          max-width="50"
          position="left"
          style="cursor:pointer;"
          v-bind="$attrs"
          v-on="$listeners"
        />
      </div>
    </v-card-subtitle>
  </v-card>
</template>

<script>
export default {
  name: 'ListItemCommonProject',

  props: {
    entity: {
      type: Object,
      required: true
    },

    selectable: {
      type: Boolean,
      required: false,
      default: false
    },

    minWidthProp: {
      type: String,
      default: '249px'
    }
  },
  computed: {
    getImage () {
      if (this.entity.image) {
        return this.entity.image.url
      }
      else if ( this.entity.picture ) {
        return this.entity.picture.url
      }
      else {
        return ''
      }
    }
  }
}
</script>

<style lang="scss" scoped>
@import '~vuetify/src/styles/styles.sass';

.v-card {
  &__subtitle {
    padding-right: 10px;
  }

  .select-checkbox {
    opacity: 0;
    visibility: hidden;
    transform: translateX(5px);
    transition: transform $secondary-transition,
    opacity $secondary-transition,
    visibility $secondary-transition;
  }

  .list-item-name {
    color: var(--v-grey-darken1);
    width: 100%;
    overflow: hidden;
    white-space: normal;
    font-size: 0.875rem !important;

    .theme--light.v-btn {
      color: var(--v-grey-darken1);

    }
  }

  &:hover,
  &.selected {
    .select-checkbox {
      opacity: 1;
      visibility: visible;
      transform: translateX(0px);
    }

    .list-item-name {
      color: var(--v-primary-base)
    }
  }
}
</style>
