
export default async function organization ({to,next, store, nextMiddleware }) {

  const orgState = store.getters['organization/getSelectedOrganization']
  const orgSessionStorage = JSON.parse(sessionStorage.getItem('organization') )

  if (!orgState && orgSessionStorage) {
    await store.dispatch('organization/updateSelectedOrganization', orgSessionStorage)
  }


  else if (!orgState && to.path !== '/organization') {
    return next({
      path: '/organization'
    })
  }

  return nextMiddleware()

}

