// import store from '@/store'

const enterKeyPlugin = {
  install(Vue) {
    Vue.prototype.$enterKey = {
      addListener(callback) {
        function handleKeyup(event) {
          event.stopPropagation()
          if (event.key === 'Enter') {
            callback()

            // Deyan Mihov requested to remove this functionality, because there is a close(x) button on entire browser

            // const isQuitDialogVisible = store.getters['quitDialog/getIsQuitDialogVisible']
            // if (!isQuitDialogVisible) {
            //   callback()
            // }
          }
        }
        window.addEventListener('keyup', handleKeyup)

        // Provide a way to remove the event listener
        return () => {
          window.removeEventListener('keyup', handleKeyup)
        }
      }
    }
  }

}

export default enterKeyPlugin
