import { BaseClass } from '@/classes/base/BaseClass'
export class ProductReleaseConfigurator extends BaseClass {
  constructor (data = {}) {
    super()

    this.id = data.id
    this.active = data.active
    this.created_at = data.created_at
    this.macos_file_id = data.macos_file_id
    this.mandatory_users = data.mandatory_users
    this.updated_at = data.updated_at
    this.version = data.version
    this.windows_file_id = data.windows_file_id

    this._versionAsInt = parseInt(this.version.replace(/\./g, ''))
  }

  get versionUrl () {
    if (this.windows_file_id) {
      return this.windows_file_id.url
    }
    return this.macos_file_id.url
  }
}


