<template>
  <div>
    <v-card
      :ripple="false"
      class="v-card--bordered d-flex align-center mt-5 mb-5 pl-5 configuration-selection"
    >
      <v-card-subtitle class="py-2 pr-2 pl-0">
        <div class="d-flex align-center list-item-name">
          <div class="ml-6 mr-5">
          </div>

          <div class="text-h5">
            {{msgProp}}
          </div>
        </div>
      </v-card-subtitle>
    </v-card>

    <v-card
      :ripple="false"
      class="v-card--bordered d-flex align-center mb-5 pl-5 configuration-selection__empty"
      elevation="0"
    >
      <v-card-subtitle class="py-2 pr-2 pl-0">
        <div class="d-flex align-center list-item-name">
          <div class="ml-6 mr-5">
          </div>

          <div class="text-h5">
          </div>
        </div>
      </v-card-subtitle>
    </v-card>

    <v-card
      :ripple="false"
      class="v-card--bordered d-flex align-center mb-5 pl-5 configuration-selection__empty"
      elevation="0"
      style="opacity: 0.5;"
    >
      <v-card-subtitle class="py-2 pr-2 pl-0">
        <div class="d-flex align-center list-item-name">
          <div class="ml-6 mr-5">
          </div>

          <div class="text-h5">
          </div>
        </div>
      </v-card-subtitle>
    </v-card>
  </div>
</template>

<script>

export default {
  name: "ConfigurationOrTemplateStepOverviewEmptyList",
  props: {
    msgProp: {
      type: String,
      required: true
    }
  }
}
</script>

<style lang="scss" scoped>
.list-container {
  width: 75%;
  @media only screen and (max-width: 1900px) {
    width: 100%;
    padding: 0;
  }
}

.list-item-name {
  color: var(--v-grey-darken1);
  overflow: hidden;
  height: 60px;
  line-height: normal;

  .theme--light.v-btn {
    color: var(--v-grey-darken1);
  }

  .info-text {
    color: var(--v-grey-lighten1);
  }
}


.configuration-selection {
  &__icon {
    border: 2px solid var(--v-grey-darken2);
    border-radius: 100%;
    width: 24px;
    height: 24px;
  }

  &__empty {
    background: var(--v-grey-lighten2);
  }
}

</style>
