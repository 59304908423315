<template>
  <div class="loader-container">

    <div class="logo-container">
      <v-img
          :src="selectedProduct.logo ? selectedProduct.logo.url : ''"
          contain
          width="350"
          height="70"
          class="pt-2"
      />
    </div>

    <div class="progress-container" style="width: 200px">
      <v-progress-linear
          v-show="showLoadingBar"
          color="primary"
          background-color="white"
          height="10"
          indeterminate
          rounded
      ></v-progress-linear>
    </div>

    <div class="most-tip-container mt-10 ">
      <div v-for="(text, index) in currentText" :key="index" >
        {{ text }}
      </div>
    </div>

    <v-btn
      v-if="!showLoadingBar"
      class="hover-btn-gray"
      color="primary"
      rounded
      @click="maximizeConfigurator"
    >
      Show
    </v-btn>

    <div class="tips-container">
      <app-svg
          src="~/assets/img/icons/lamp.svg"
          size="24"
          color="var(--v-grey-lighten5)"
      />

      <div class="tip-header mt-1 ">
        Quick Tips
      </div>

      <transition name="slide-fade" mode="out-in">
        <div :key="currentRandomTip" class="px-4 pt-4 exact-tip-container">
          {{ currentRandomTip }}

        </div>
      </transition>

    </div>

  </div>
</template>


<script>
import AppSvg from '@/components/app/AppSvg.vue'

import { mapGetters } from 'vuex'

export default {
  name: 'LoadingView',

  components: { AppSvg },

  data () {
    return {
      tips: [
        'To delete a module, right-click on it with your mouse',
        'To change the partition wall type, click on the wall.',
        'You can save your current configuration as a template for future use.'
      ],

      currentRandomTip: null,

      text1: ['Good things take time.',' We are starting the configurator.'],
      text2: ['Good things take time.', 'We are loading the floor selection.'],
      text3: ['The configurator is running.'],
      text4: ['Good things take time.', 'We are loading the selected building and catalogues.'],
      text5: ['The configurator is running.'],

      timeIntervalId: null,

      currentText: null,
    }
  },

  watch: {
    getaAbstractStartLoading (value) {
      if (value) {
        this.currentText = this.text2
      }
    },

    getAbstractEndLoading (value) {
      if (value) {
        this.currentText = this.text3
      }
    },

    getFloorStartLoading (value) {
      if (value) {
        this.currentText = this.text4
      }
    },

    getFloorEndLoading (value) {
      if (value) {
        this.currentText = this.text5
      }
    }
  },

  computed: {
    ...mapGetters({
      getaAbstractStartLoading: 'loading/getaAbstractStartLoading',
      getAbstractEndLoading: 'loading/getAbstractEndLoading',
      getFloorStartLoading: 'loading/getFloorStartLoading',
      getFloorEndLoading: 'loading/getFloorEndLoading'
    }),

    showLoadingBar () {
      if (this.currentText === this.text3 || this.currentText === this.text5) {
        return false
      }
      return true
    },


    selectedProduct () {
      return this.$store.getters['product/getSelectedProduct']
    },
  },

  methods: {
    setRandomTip () {
      const randomIndex = Math.floor(Math.random() * this.tips.length)
      this.currentRandomTip = this.tips[randomIndex]
    },
    maximizeConfigurator() {
      try {
        if (window.vcreateInterface && window.vcreateInterface.unityCall) {
          const payload = {
            callType: 'Event',
            callName: 'Maximize',
            callParams: ''
          }

          window.vcreateInterface.unityCall(JSON.stringify(payload))
        }
      } catch (e) {
        console.log(e)
        this.$error(e)
      }
    }
  },

  created () {
    this.setRandomTip()
    this.timeIntervalId = setInterval(this.setRandomTip, 5000)
    this.currentText = this.text1
  },

  destroyed () {
    clearInterval(this.timeIntervalId)
  }

}
</script>


<style scoped lang="scss">
.loader-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
  background: url('../assets/img/grey-net-background.svg');
}

.progress-container {
  width: 230px;
  margin-top: 100px;
}

.tips-container {
  width: 400px;
  background-color: white;
  height: 125px;
  border: transparent;
  border-radius: 13px;
  box-shadow: 5px 5px 20px #00000017;
  margin-top: 130px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  color: var(--v-grey-darken2);
  text-align: center;
}

.tip-header {
  color: var(--v-grey-lighten5);
}

.most-tip-container {
  width: 400px;
  text-align: center;
}

.exact-tip-container {
  flex: 1;
  height: 400px;
}


.slide-fade-enter-active,
.slide-fade-leave-active {
  transition: all 0.5s ease;
}

.slide-fade-enter-from {
  transform: translateX(-200px); /* Enter from left */
  opacity: 0.5;
}

.slide-fade-leave-to {
  transform: translateX(20px); /* Exit to right */
  opacity: 0;
}


</style>
