
export function convertVersionTo2dots (version) {
  if (version) {
    let parts = version.split('.');

    if (parts.length === 4) {
      return parts.slice(0, -1).join('.');
    } else if (parts.length === 3) {
      return version;
    } else {
      throw new Error(`Installed Version is invalid format - current version ${version} `)
    }
  }
  return null
}

function convertVersionLeadingZero (version) {
  const parts = version.split('.')
  const paddedParts = parts.map((part, index) =>
    index === parts.length - 1 ? part.padStart(4, '0') : part.padStart(2, '0')
  )

  return paddedParts.join('.')
}
function compareVersions ({beVersionObj, installedVersion}) {
  if (installedVersion) {
    const installedVersionFormatted = convertVersionTo2dots(installedVersion)
    const installedVersionLeadingZero = convertVersionLeadingZero(installedVersionFormatted)

    const beVersionAsInt = parseInt(beVersionObj.version.replace(/\./g, ''))
    const installedVersionAsInt = parseInt(installedVersionLeadingZero.replace(/\./g, ''))


    if (beVersionAsInt > installedVersionAsInt) {
      return beVersionObj
    }

  } else {
    return beVersionObj
  }
  return null

}

export function getHighestBeVersion ({ beVersions, installedVersion}) {
  const mandatoryVersion = beVersions.find(item => item.mandatory_users)
  const notMandatoryVersion = beVersions.find(item => item.active)

  if (mandatoryVersion || notMandatoryVersion) {

    // mandatoryVersion is with the highest priority
    if (mandatoryVersion) {
     const result = compareVersions({ beVersionObj: mandatoryVersion,  installedVersion})
      if (result) {
        return  result
      }
    }

    if (notMandatoryVersion) {
      const result =  compareVersions({ beVersionObj: notMandatoryVersion,  installedVersion})
      if (result) {
        return  result
      }
    }
  }
  return null
}