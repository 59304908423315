<template>
  <v-dialog
    :max-width="maxWidth"
    :persistent="persistent"
    :value="value"
    overlay-color="#000"
    overlay-opacity="0.65"
    scrollable
    v-bind="$attrs"
    v-on="$listeners"
    @click:outside="hide"
  >
    <v-card>
      <v-toolbar color="grey lighten-2" elevation="0">
        <v-toolbar-title class="text-h4 grey-darken1-text">
          {{ titleProp }}
        </v-toolbar-title>

        <template v-if="!persistent">
          <v-spacer />
          <v-btn
            id="app-dialog-hide-buttoF"
            color="black"
            icon
            @click.prevent="hide"
          >
            <app-svg
              color="black"
              size="16"
              src="@/assets/img/icons/close.svg"
            />
          </v-btn>
        </template>
      </v-toolbar>

      <div class="scrollable-dialog-content grey-darken1-text">
        <slot :hide="hide" />
      </div>

      <template v-if="hasActionsSlot">
        <v-divider />
        <v-card-actions class="pa-5">
          <slot :hide="hide" name="actions" />
        </v-card-actions>
      </template>
    </v-card>
  </v-dialog>
</template>

<script>
import AppSvg from '@/components/app/AppSvg'

export default {
  name: 'AppDialog',
  components: { AppSvg },

  props: {
    value: Boolean,
    persistent: Boolean,
    titleProp: {
      type: String,
      required: true
    },
    maxWidth: {
      type: [String, Number],
      default: 1140
    }
  },

  computed: {
    show: {
      get () {
        return !!this.value
      },
      set (value) {
        this.$emit('input', !!value)
      }
    },

    hasActionsSlot () {
      return this.$scopedSlots && this.$scopedSlots.actions
    }
  },

  watch: {
    value (newValue) {
      if (newValue) {
        this.$emit('show')
        return
      }

      this.$emit('hide')
    }
  },

  methods: {
    hide () {
      this.show = false
      this.$emit('dialogClose')
    }
  }
}
</script>

<style lang="scss">
.scrollable-dialog-content {
  flex: 1 1 auto;
  overflow-y: auto;
  height: 100%;
  padding: 35px 25px;
}

.grey-darken1-text {
  color: var(--v-grey-darken1);
}

</style>
