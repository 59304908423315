<template>
  <div>
    <h3 class="mb-13 text-center text-h3 " style="color: #909090;">
      Working on: {{ selectedProject.name }}
    </h3>

    <v-container class="header-container pa-0 mb-6">

      <v-row>
        <v-col
            class="pa-0 no-gutters"
            cols="12"
            lg="4"
            md="12"
        >
        </v-col>
        <v-col
            class="pl-0 pt-0 pb-1 no-gutters"
            cols="12"
            lg="8"
            md="12"
        >
          <div class="d-flex justify-space-between align-center">
            <div>
              <v-tabs
                  v-model="selectedTab"
                  active-class="tab-active"
                  hide-slider
                  show-arrows
              >
                <v-tab
                    key="configurations"
                    href="#configurations"
                >
                  Configurations
                </v-tab>
                <v-tab
                    key="templates"
                    href="#templates"
                    :disabled="selectedProduct.name !== 'Office'"
                >
                  Templates
                </v-tab>

              </v-tabs>
            </div>

            <div class="header-search-wrapper">
              <v-text-field
                  v-model="searchInput"
                  id="search-bar"
                  placeholder="Search"
                  height="30px"
                  rounded
                  class="ma-0 pa-0"
                  clearable
                  outlined
                  dense
              >
                <template #prepend-inner>
                  <app-svg
                      src="~/assets/img/icons/search.svg"
                      size="16"
                      color="var(--v-grey-darken1)"
                  />
                </template>
              </v-text-field>
            </div>
          </div>
        </v-col>
      </v-row>
    </v-container>

    <v-tabs-items v-model="selectedTab" class="tab-content-container">
      <v-tab-item
          key="configurations"
          value="configurations"
          v-if="selectedTab === 'configurations'"
      >
        <configuration-step-overview-container ref="configurationsTab" />
      </v-tab-item>

      <v-tab-item
          key="templates"
          value="templates"
          v-if="selectedTab === 'templates'"
      >
      <configuration-template-step-overview-container ref="templatesTab" />
      </v-tab-item>

    </v-tabs-items>


  </div>
</template>

<script>

import ConfigurationStepOverviewContainer from '@/components/configuration/ConfigurationStepOverviewContainer'
import ConfigurationTemplateStepOverviewContainer
  from '@/components/configuration/ConfigurationTemplateStepOverviewContainer.vue'
import AppSvg from '@/components/app/AppSvg.vue'

import { mapGetters } from 'vuex'
export default {
  name: "ConfigurationView",
  components: { ConfigurationStepOverviewContainer, AppSvg, ConfigurationTemplateStepOverviewContainer },

  data () {
    return {
      searchInput: null
    }
  },

  computed: {
    ...mapGetters(
        {
          selectedProduct: 'product/getSelectedProduct',
          selectedProject: 'project/getSelectedProject',
        }
    ),

    selectedTab: {
      get () {
        return this.$route.query.tab
      },
      async set (tab) {
        await this.$router.push({
          query: { ...this.$route.query, tab }
        })
      }
    }
  },

  watch: {
    searchInput (value) {
      this.searchInConfigurations(value)
    }
  },

  methods: {
    searchInConfigurations (value) {
      switch (this.selectedTab) {
        case 'configurations':
          this.$refs.configurationsTab.makeSearch(value)
          break

        case 'templates':
          this.$refs.templatesTab.makeSearch(value)
      }


    }
  }
}
</script>

<style scoped lang="scss">
.header-container {
  width: 75%;
  @media only screen and (max-width: 1900px) {
    width: 100%;
    padding: 0;
  }
}

.tab-content-container {
  background-color: var(--v-grey-lighten3);
}
</style>
