
let errorHandler = {
  install (Vue) {
    Vue.prototype.$error = async function (e) {
      let errorMsg = ''
      if (e.response && e.response.data && e.response.data.errors) {
        errorMsg =  e.response.data.errors

      } else if (e.message) {
        errorMsg = e.message
      } else {
        errorMsg = 'Unexpected Error!'
      }


      await this.$store.dispatch('snackbar/showSnackbarMessage', {
        message: errorMsg  ,
        duration: 4000,
        mode: 'fail'
      })

    }
  }
}

export default errorHandler