import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import vuetify from './plugins/vuetify'
import Vuelidate from 'vuelidate'
import $axios from './plugins/axios'
import VueCookies from 'vue-cookies'
import search from '@/plugins/search'
import error from '@/plugins/error'
import featureConstants from '@/plugins/featureConstants'
import access from '@/plugins/access'
import time from '@/plugins/time'
import enterKeyPlugin from '@/plugins/enterKeyPlugin'

import './styles/main.scss'
import './styles/_tooltip.scss'
import './styles/_button.scss'
import './styles/_text-field.scss'
import './styles/_tabs.scss'
import './styles/_search-box.scss'
import './styles/_skeleton-loader.scss'
import './styles/_scrollbar.scss'
import './styles/_lists.scss'
import './styles/_image.scss'

import 'animate.css'

Vue.config.productionTip = false

/** Vuelidate plugin settings */
Vue.use(Vuelidate)

/** Custom Plugins plugin settings */
Vue.use(search)
Vue.use(error)

Vue.use(featureConstants)
Vue.use(access)

Vue.use(time)

Vue.use(enterKeyPlugin)

/** Axios plugin settings */
Vue.use({
  install (Vue) {
    Vue.prototype.$axios = $axios
  }
})

/** Vue cookies */
Vue.use(VueCookies)

new Vue({
  router,
  store,
  vuetify,
  render: h => h(App)
}).$mount('#app')
