<template>
  <div>
    <v-container class="list-container">
      <v-row>
        <v-col
            class="pt-0 no-gutters project-scrollbar"
            cols="12"
            lg="3"
            md="12"
            style="overflow-y:auto; height:550px;"
        >
          <list-overview
              :can-create=true
              :cols-prop="{ cols: 12, sm: 12, md: 12, lg: 12, xl: 12 }"
              :data-source="projectsProp"
              :is-list-loading-prop="isListLoadingProp"
              :selectable="true"
              list-type="project"
          >
            <template #entity-card="{ entity, selectable }">
              <list-item-common-project
                  :class="highlightSelectedProjectClass(entity.id)"
                  :entity="entity"
                  :selectable="selectable"
                  class="cursor-pointer mt-1"
                  @click.native="$emit('selectProject', entity)"
              />
            </template>
          </list-overview>
        </v-col>

        <v-col
            class="pt-0 pl-lg-9"
            cols="12"
            lg="9"
            md="12"
        >
          <div>
            <v-card
                v-if="selectedProjectProp"
                :ripple="false"
                class="v-card--bordered"
                height="550"
                style="overflow: hidden;"
            >
              <v-img
                  :src="getSelectedProjectImage"
                  height="281"
                  class="project-image"
              />
              <v-card-subtitle class="pa-7 mt-4 pb-4">
                <v-row>
                  <v-col cols="7">
                    <v-row>
                      <v-col class="pa-0">
                        <div class="d-flex align-center">
                          <div class="image-container">
                            <v-img
                                id="entity-logo"
                                :src="selectedProjectProp.logo ? selectedProjectProp.logo.url : ''"
                                contain
                                max-height="35"
                                max-width="50"
                                position="left"
                                style="cursor:pointer"
                                class="pa-4"
                                v-bind="$attrs"
                                v-on="$listeners"
                            />
                          </div>


                          <div class="pl-4  d-flex flex-column justify-start" style="height: 45px">
                            <div class="project-name text-h3">
                              {{ selectedProjectProp.name }}
                            </div>

                            <div style="font-size: 12px" class="project-location"> {{ selectedProjectProp.location }}</div>
                          </div>
                        </div>
                      </v-col>
                    </v-row>

                    <v-row class="mt-4">
                      <v-col class="py-0">
                        <div
                            class="text-h6 pr-3 mt-4 project-description"
                            style="
                            overflow-y: auto;
                            height: 160px;
                            white-space: pre-wrap;
                            word-break: break-word;
                          "
                        >
                          {{ selectedProjectProp.description }}
                        </div>
                      </v-col>
                    </v-row>
                  </v-col>

<!--                  Catalogues-->
                  <v-col
                      v-if="selectedProduct.isSandbox"
                      class="pl-6 pt-0 pb-0 catalogues-wrapper"
                      cols="5"
                      style="height: 220px;"
                  >

                    <div class="d-flex flex-row justify-start">
                      <v-divider vertical/>

                      <div
                          v-if="selectedProjectProp.catalogues.length"
                          class="d-flex flex-column "
                          style="max-height: 220px"
                      >
                        <v-row class="mb-0 pl-4 pt-2">
                          <v-col class="catalogues-header">
                            Available catalogues
                          </v-col>
                        </v-row>

                        <div class="catalogues-container pr-2 pl-4 mt-9" style="height: 160px">
                          <v-row
                              class="ma-0 catalog-item"
                              v-for="(catalog, index) in selectedProjectProp.catalogues"
                              :key="catalog.id"
                          >
                            <v-col class="pa-1">

                              <div class="d-flex flex-row justify-space-between pl-4 catalog-name">
                                <div>
                                  {{ catalog.name }}
                                </div>

                                <v-img
                                    max-height="35"
                                    max-width="50"
                                    :src="catalog.image ? catalog.image.url : ''"
                                    alt="no catalog img"
                                    class="mr-4"
                                >
                                </v-img>
                              </div>

                              <v-divider v-if="index+1 < selectedProjectProp.catalogues.length" class="ma-2"/>
                            </v-col>
                          </v-row>
                        </div>
                      </div>

                      <div v-else class="pl-4" style="height: 220px">
                        <v-row class="mb-0 pt-2">
                          <v-col>
                            No catalogues attached
                          </v-col>
                        </v-row>
                      </div>

                    </div>
                  </v-col>

<!--                  Lifestyles-->
                  <v-col
                      v-if="selectedProduct.isOffice && canAccessLifeStyle"
                      class="pl-6 d-flex pt-0 justify-space-between flex-column catalogue-list lifestyle-wrapper"
                      cols="5"
                  >

                    <div class="d-flex flex-row justify-start">
                      <v-divider vertical/>

                      <div class="life-styles-container pl-4 mt-3">
                        <div class="life-style-header">
                          Lifestyles
                        </div>

                        <div class="life-style-description mt-3">
                          Lifestyles are sets of predefined colors and materials of the furniture in the particular
                          Project. Project Managers can change the lifestyle sets or create new ones.
                          These changes will immediately be available to configurator users.
                        </div>

                        <v-btn
                            class="mt-10 hover-btn-gray btn-with-font-weight hover-outlined-btn-primary"
                            color="var(--v-grey-lighten2)"
                            rounded
                            small
                            :loading="isLaunchButtonLoading"
                            @click.stop.prevent="openStartGame"
                            :key="keyLaunchBtn"
                        >
                          Edit Lifestyles
                        </v-btn>
                      </div>
                    </div>

                  </v-col>

                </v-row>
              </v-card-subtitle>
            </v-card>

            <v-card
                v-else
                :ripple="{ class: 'primary--text' }"
                class="v-card--bordered empty-selected-project-card d-flex justify-center align-center "
                disabled
                height="550"
            >
              <v-card-subtitle>

                <div v-if="isListLoadingProp">
                  <v-skeleton-loader min-width="550" type="sentences"/>
                </div>

                <div v-else>
                  <div class="text-h5 text-center no-projects-wrapper" v-if="!hasProjects() && !isListLoadingProp">
                    There are no Projects assigned to your user profile. Please contact an administrator who
                    can assign you with a “configurator” role to a particular project.
                  </div>

                  <div class="text-h5 text-center select-project-tip" v-if="!selectedProjectProp && hasProjects()">
                    Select a project to preview content
                  </div>
                </div>


              </v-card-subtitle>
            </v-card>


          </div>
        </v-col>
      </v-row>
    </v-container>
  </div>
</template>

<script>
import ListOverview from '@/components/list/ListOverview'
import ListItemCommonProject from '@/components/list/ListItemCommonProject'

import { mapGetters } from 'vuex'
import { TokenClass } from '@/classes/auth/TokenClass'
import { gameStarted } from '@/utils/gameStart'


import { v4 as uuidv4 } from 'uuid'

export default {
  name: 'ProjectListOverview',
  components: { ListItemCommonProject, ListOverview },
  props: {
    projectsProp: {
      type: Array,
      required: true
    },
    isListLoadingProp: {
      type: Boolean,
      required: true
    },
    selectedProjectProp: {
      type: Object,
      default: null
    }
  },

  data () {
    return {
      projects: this.projectsProp,

      isLaunchButtonLoading: false,

      keyLaunchBtn: uuidv4(),

      keyProjectCardText: uuidv4(),

    }
  },

  computed: {
    ...mapGetters(
        {
          selectedProduct: 'product/getSelectedProduct',
          selectedOrganization: 'organization/getSelectedOrganization',
          user: 'auth/getCurrentUser',
          unityCloseKey: 'unity/getUnityCloseKey'
        }
    ),

    canAccessLifeStyle () {
      if (this.user && this.user._roles) {
        return this.$canAccessFeature({
          featureName: this.$featureConstants.LIFE_STYLE,
          userRoles: this.user._roles,
          useRolesPerProject: this.selectedProjectProp.request_user_permissions
        })
      }
      return false

    },

    getSelectedProjectImage () {
      if (this.selectedProjectProp.image) {
        return this.selectedProjectProp.image.url
      } else if (this.selectedProjectProp.picture) {
        return this.selectedProjectProp.picture.url
      } else {
        return ''
      }
    },

    userToken () {
      return TokenClass.userToken
    }
  },

  watch: {
    projectsProp () {
      this.hasProjects()
    },

    unityCloseKey () {
      this.onUnityClose()
    }
  },

  methods: {
    payloadStartGame () {
      return {
        'AccessToken': this.userToken,

        'OrganizationId': this.selectedOrganization.id,
        'OrganizationName': this.selectedOrganization.name,
        'OrganizationOwnerId': this.selectedOrganization.owner_id,
        'OrganizationSlug': this.selectedOrganization.slug,

        'ProjectId': this.selectedProjectProp.id,
        'TechnicalProjectId': this.selectedProjectProp.technical_project_id,
        'ClientName': this.selectedProjectProp.name,

        'SaveName': '',
        'SaveCode': '',
        'GameName': this.selectedProduct.name,
        'Lang': this.selectedProduct.language?.value,

        'TemplateId': '',
        'GameMode': 'LifeStyle'
      }
    },


    highlightSelectedProjectClass (entityId) {
      if (this.selectedProjectProp && this.selectedProjectProp.id) {
        return (entityId === this.selectedProjectProp.id) ? 'active-item' : ''
      }
    },

    openStartGame () {
      console.log(this.payloadStartGame(), 'payload')

      this.isLaunchButtonLoading = true

      try {
        const payload = this.payloadStartGame()

        window.vcreateInterface.startGame(JSON.stringify(payload))

        setTimeout(() => {
          this.$nextTick(() => {
            this.isLaunchButtonLoading = false
          })
        }, 5000)
        this.isGameStarted()

      } catch (e) {
        this.$error(e)
        this.isLaunchButtonLoading = false
      }
    },

    isGameStarted () {
      return gameStarted(this.selectedProduct.name)
    },

    onUnityClose () {
      this.keyLaunchBtn = uuidv4()
    },

    hasProjects () {
      return !!this.projectsProp.length
    }
  },
}
</script>

<style lang="scss" scoped>
.active-item {
  border: 2px solid;
  border-color: var(--v-primary-base);
}

.inactive-item {
  border: 1px solid #707070;
  opacity: 0.2;
}

.catalogue-list {
  .catalogue-item {
    padding-left: 27px;
  }
}

.list-container {
  width: 75%;
  @media only screen and (max-width: 1900px) {
    width: 100%;
    padding: 0;
  }
}

/* width */
::-webkit-scrollbar {
  width: 4px;
}

/* Track */
::-webkit-scrollbar-track {
  background: var(--v-grey-lighten2);
  border-radius: 100px;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: var(--v-primary-base);
  border-radius: 100px;
}

.empty-selected-project-card {
  background: var(--v-grey-lighten2);
  border: 1px solid var(--v-grey-lighten6);
  border-radius: 6px;
}

.project-name {
  overflow: hidden;
  height: 100px;
  line-height: normal;
  font-size: 1.375rem;
  color: var(--v-grey-lighten5);
}

.life-styles-container {
  font-size: 12px;
}

.life-style-header {
  color: var(--v-grey-darken2);
  font-weight: bold;
}

.life-style-description {
  color: var(--v-grey-darken1);
}

.image-container {
  width: 62px;
  height: 62px;
  border-radius: 100%;
  border: 1px solid var(--v-grey-lighten6);
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}


.catalogues-container {
  overflow-y: auto;
  overflow-x: hidden;
  min-width: 300px;
}

</style>
