import { actionStates } from '@/constants'
import ProductService from '@/services/product.service'

import { ProductClass } from '@/classes/product/ProductClass'
import { ProductReleaseConfigurator } from '@/classes/product/ProductReleaseConfigurator'

import { getHighestBeVersion } from '@/utils/versionCheck'

import Vue from 'vue'

const state = {
  currentState: actionStates.INITIAL,
  selectedProduct: null,
  products: [],
  anyProductUpdatingLoading: false
}

const getters = {
  getProducts (state) {
    return state.products.sort((a, b) =>  b.name.localeCompare(a.name))
  },

  getCurrentState (state) {
    return state.currentState
  },

  getSelectedProduct (state) {
    return state.selectedProduct
  },

  getAnyProductUpdatingLoading (state) {
    return state.anyProductUpdatingLoading
  }
}

const mutations = {
  setActionState (state, actionState) {
    state.currentState = actionState
  },

  setProducts (state, products) {
    state.products = products ?? []
  },

  setSelectedProduct (state, id) {
    state.selectedProduct = id
  },

  setSelectedProductLang (state, langObj) {
    if (state.selectedProduct.isOffice) {
      Vue.set(state.selectedProduct, 'language', langObj)

      sessionStorage.setItem('product', JSON.stringify(state.selectedProduct))
    }
  },

  setAnyProductUpdatingLoading (state, value) {
    state.anyProductUpdatingLoading = value
  }
}

const actions = {
  async fetchAllProducts (vuexContext, slug) {
    vuexContext.commit('setActionState', actionStates.LOADING)

    try {
      let response = await ProductService.getAllProducts(slug)

      const products = ProductClass.createList(response)

      if (!response.length) {
        sessionStorage.removeItem('product')
        vuexContext.commit('setSelectedProduct', null)
      }

      if (window.vcreateInterface) {
        const installedVersionsResponse = JSON.parse(window.vcreateInterface.getInstalledSoftware())

        for (const product of products) {
          const productInstalledVersion = installedVersionsResponse ? installedVersionsResponse[`${product.name}`] : null

          const releasesResponse = await ProductService.getConfiguratorReleases(product.id)
          const releases = ProductReleaseConfigurator.createList(releasesResponse)
          product._installedVersion=productInstalledVersion

          const highestVersion = getHighestBeVersion({
            beVersions: releases,
            installedVersion: installedVersionsResponse[`${product.name}`]
          })

          if (highestVersion) {
            product._highestVersion = highestVersion
          }
        }
      }


      vuexContext.commit('setProducts', products)

      vuexContext.commit('setActionState', actionStates.DATA_LOADED)

    } catch (e) {
      vuexContext.commit('setProducts', [])
      vuexContext.commit('setSelectedProduct', null)

      if (e && e.response && e.response.status === 404) {
        vuexContext.commit('setActionState', actionStates.NOT_FOUND)
      } else {
        vuexContext.commit('setActionState', actionStates.ERRORED)
      }
    }
  },

  async updateAllProducts (vuexContext, data) {
    vuexContext.commit('setProducts', data.products)
  },

  updateSelectedProduct (vuexContext, payload) {
    let selectedProduct = vuexContext.getters.getSelectedProduct

    if (selectedProduct && selectedProduct.id === payload.id) {
      vuexContext.commit('setSelectedProduct', null)
      return
    }
    vuexContext.commit('setSelectedProduct', new ProductClass(payload))
    sessionStorage.setItem('product', JSON.stringify(payload))
  },

  removeSelectedProduct (vuexContext) {
    sessionStorage.removeItem('product')
    vuexContext.commit('setSelectedProduct', null)
  },

  updateSelectedProductLang (vuexContext, langObj) {
    vuexContext.commit('setSelectedProductLang', langObj)
  },

  updateAnyProductUpdatingLoading (vuexContext, value) {
    vuexContext.commit('setAnyProductUpdatingLoading', value)
  }
}

export { actionStates }

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions
}
