<template>
<div>
  <app-dialog
      v-model="isDialogVisible"
      :title-prop="'Create New Configuration From Template'"
      max-width="500"
      v-bind="$attrs"
      v-on="$listeners"
  >
    <v-hover>
      <template v-slot="{ hover }">
        <v-text-field
            v-model="confTemplateName"
            label="Set the configuration name"
            color="primary"
            class="filled-input"
            :class="hover ? 'fill-on-hover' : ''"
            required
            :error-messages="nameErrors"
            @blur="$v.$touch()"
            @input="$v.confTemplateName.$touch()"
        />
      </template>
    </v-hover>


    <template #actions>
      <v-spacer class="d-none d-sm-flex" />
      <v-row>
        <v-col cols="12" sm="6">
          <v-btn
              id="close-dialog-button"
              block
              color="primary"
              outlined
              raised
              rounded
              large
              @click.prevent="hideDialog"
              class="ml-3"

          >
            Cancel
          </v-btn>
        </v-col>

        <v-col cols="12" sm="6">
          <v-btn
              id="submit-button"
              block
              color="primary"
              raised
              rounded
              large
              :loading="loading"
              :disabled="isContinueBtnDisabled"
              @click.prevent="startGame"
          >
            <span class="text-capitalize">
              Continue
            </span>
          </v-btn>
        </v-col>
      </v-row>

    </template>

  </app-dialog>
</div>
</template>



<script>
import AppDialog from '@/components/app/AppDialog'

import { mapGetters } from 'vuex'
import { maxLength, required, helpers } from 'vuelidate/lib/validators'

import { enterKeyListenerMixin } from '@/mixins/enterKeyListenerMixin'

export default {
  name: 'ConfigurationTemplateNameDialog',

  mixins: [enterKeyListenerMixin],

  components: {
    AppDialog
  },

  props: {
    value: {
      type: Boolean,
      default: false,
      required: true
    }
  },

  validations: {
    confTemplateName: {
      required,
      maxLength,
      validChars: helpers.regex('valid chars',
          new RegExp(/^[a-zA-Z0-9`~!@#$%^&*()\-_=+[\]{}|;:'",.<>/?\\ äöüß]*$/u, 'u')
      )
    }
  },

  data () {
    return {
      confTemplateName: null,
      loading: false,
    }
  },

  computed: {
    ...mapGetters(
        {
          configurations: 'configuration/getConfigurations',
        }),

    isDialogVisible: {
      get () {
        return this.value
      },

      set (value) {
        this.$emit('input', value)
      }
    },

    isConfTemplateNameAlreadyUsed () {
      const allConfNames = this.configurations.map(item => item.name)

      if (this.confTemplateName && allConfNames.length) {
        return allConfNames.includes(this.confTemplateName)
      }
      return false
    },

    nameErrors () {
      const errors = []
      if (!this.$v.confTemplateName.$dirty) {
        return errors
      }
      this.isConfTemplateNameAlreadyUsed && errors.push('There is already configuration with such a name, please use different one')
      !this.$v.confTemplateName.maxLength && errors.push('Can contain maximum of 255 characters')
      !this.$v.confTemplateName.required && errors.push('Must contain at least 1 character')
      !this.$v.confTemplateName.validChars && errors.push('Can contain only latin characters, numbers and symbols')
      return errors
    },

    isContinueBtnDisabled () {
      return this.$v.$anyError || !this.confTemplateName || this.isConfTemplateNameAlreadyUsed
    }
  },

  watch: {
    confTemplateName () {
      this.toggleEnterKeyListener({isDisabled: this.isContinueBtnDisabled, callback: this.startGame })
    }
  },


  methods: {
    async hideDialog () {
      this.isDialogVisible = false
    },

    startGame () {
      this.loading = true
      this.$emit('startGame', this.confTemplateName)
      this.loading = false
      this.hideDialog()
    }
  },

  mounted () {
    // Register the Enter key listener
    // Register the Enter key listener
    this.toggleEnterKeyListener({isDisabled: this.isContinueBtnDisabled, callback: this.startGame })
  },

  beforeDestroy() {
    //Remove the Enter key listener
    if (this.cleanup) {
      this.cleanup()
    }
  }


}
</script>



<style scoped lang="scss">

</style>