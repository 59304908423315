import ProjectService from '@/services/project.service'

import { actionStates, userRoleConstants } from '@/constants'

const state = {
  currentState: actionStates.INITIAL,
  selectedProject: null,
  projects: []
}

const getters = {
  getProjects (state) {
    return state.projects.sort((a, b) => a.name.localeCompare(b.name))
  },

  getCurrentState (state) {
    return state.currentState
  },

  getSelectedProject (state) {
    return state.selectedProject
  }
}

const mutations = {
  setActionState (state, actionState) {
    state.currentState = actionState
  },

  setProjects (state, projects) {
    state.projects = projects ?? []
  },

  setSelectedProject (state, id) {
    state.selectedProject = id
  }
}

const actions = {
  async fetchAllProjects (vuexContext, data) {
    vuexContext.commit('setActionState', actionStates.LOADING)

    try {
      let response = await ProjectService.getAllProjects(data.slug, data.api_domain)

      const selectedProduct  = vuexContext.rootGetters['product/getSelectedProduct']
      if (selectedProduct && selectedProduct.isOffice) {
        await vuexContext.dispatch('auth/fetchUserProfile', data, { root: true })
        const filteredProjects = await vuexContext.dispatch('filterProjects', response)

        vuexContext.commit('setProjects', filteredProjects)

      } else {
        vuexContext.commit('setProjects', response)
      }

      vuexContext.commit('setActionState', actionStates.DATA_LOADED)

      if (!response.length) {
        sessionStorage.removeItem('products')
        vuexContext.commit('setSelectedProject', null)
      }

    } catch (e) {
      console.log(e, 'error')
      vuexContext.commit('setProjects', [])
      vuexContext.commit('setSelectedProject', null)

      if (e && e.response && e.response.status === 404) {
        vuexContext.commit('setActionState', actionStates.NOT_FOUND)
      } else {
        vuexContext.commit('setActionState', actionStates.ERRORED)
      }
    }
  },

  updateSelectedProject (vuexContext, payload) {
    let selectedProject = vuexContext.getters.getSelectedProject

    if (selectedProject && selectedProject.id === payload.id) {
      vuexContext.commit('setSelectedProject', null)
      return
    }
    vuexContext.commit('setSelectedProject', payload)
    sessionStorage.setItem('project', JSON.stringify(payload))
  },

  removeSelectedProject (vuexContext) {
    sessionStorage.removeItem('project')
    vuexContext.commit('setSelectedProject', null)
  },

  filterProjects (vuexContext, projects) {
    const user = vuexContext.rootGetters['auth/getCurrentUser']

      if (user && user._roles &&  user._roles.includes(userRoleConstants.SUPER_ADMIN)) {
        return projects
      }
      return projects.filter(item => item.request_user_permissions.is_configurator)
  }
}

export { actionStates }

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions
}
