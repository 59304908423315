<template>
  <app-dialog
      v-model="isDialogVisible"
      :title-prop="'New Configuration'"
      max-width="500"
      v-bind="$attrs"
      v-on="$listeners"
      @dialogClose="dialogClose"
  >
    <v-hover>
      <template v-slot="{ hover }">
        <v-text-field
            v-model="configurationName"
            label="Set the configuration name"
            color="primary"
            class="filled-input"
            :class="hover ? 'fill-on-hover' : ''"
            :error-messages="nameErrors"
            required
            @blur="$v.$touch()"
            @input="$v.configurationName.$touch()"
         />
      </template>
    </v-hover>

    <template #actions>
      <v-row class="d-flex justify-end">
        <v-col cols="4">
          <v-hover >
            <template v-slot="{ hover }">
              <v-btn
                  id="submit-button"
                  block
                  :color=" hover ? 'var(--v-grey-darken1)' : 'primary'"
                  raised
                  rounded
                  large
                  @click.prevent="confirmClick"
                  class="ml-3 text--white"
                 :disabled="isContinueBtnDisabled"
              >
                Confirm
              </v-btn>
            </template>
          </v-hover>

        </v-col>
      </v-row>

    </template>

  </app-dialog>
</template>


<script>
import AppDialog from '@/components/app/AppDialog'
import { maxLength, required, helpers } from 'vuelidate/lib/validators'
import { mapGetters } from 'vuex'

import { enterKeyListenerMixin } from '@/mixins/enterKeyListenerMixin'

export default {
  name: 'ConfigurationCreateDialog',

  mixins: [enterKeyListenerMixin],


  components: {
    AppDialog
  },

  props: {
    value: {
      type: Boolean,
      default: false,
      required: true
    }
  },

  validations: {
    configurationName: {
      required,
      maxLength,
      validChars: helpers.regex('valid chars',
          new RegExp(/^[a-zA-Z0-9`~!@#$%^&*()\-_=+[\]{}|;:'",.<>/?\\ äöüß]*$/u, 'u')
      )
    }
  },

  data () {
    return {
      configurationName: null
    }
  },

  computed: {
    isDialogVisible: {
      get () {
        return this.value
      },

      set (value) {
        this.$emit('input', value)
      }
    },

    ...mapGetters(
        {
          configurations: 'configuration/getConfigurations',
        }),

    isContinueBtnDisabled () {
      return this.$v.$anyError || !this.configurationName || this.isConfNameAlreadyUsed
    },

    isConfNameAlreadyUsed () {
      const allConfNames = this.configurations.map(item => item.name)
      if (this.configurationName && allConfNames.length) {
        return allConfNames.includes(this.configurationName)
      }
      return false
    },

    nameErrors () {
      const errors = []
      if (!this.$v.configurationName.$dirty) {
        return errors
      }
      this.isConfNameAlreadyUsed && errors.push('There is already configuration with such a name, please use different one')
      !this.$v.configurationName.maxLength && errors.push('Can contain maximum of 255 characters')
      !this.$v.configurationName.required && errors.push('Must contain at least 1 character')
      !this.$v.configurationName.validChars && errors.push('Can contain only latin characters, numbers and symbols')
      return errors
    }
  },

  watch: {
    configurationName () {
      this.toggleEnterKeyListener({isDisabled: this.isContinueBtnDisabled, callback: this.confirmClick })
    }
  },

  methods: {
    hideDialog () {
      this.isDialogVisible = false
    },

    async confirmClick () {
      await this.$store.dispatch('configuration/setSelectedConfigurationName', this.configurationName)
      this.$emit('startGame')
      this.hideDialog()
    },

    dialogClose () {
      this.$store.dispatch('configuration/removeSelectedConfiguration')
    }
  },

  mounted () {
    // Register the Enter key listener
    // Register the Enter key listener
    this.toggleEnterKeyListener({isDisabled: this.isContinueBtnDisabled, callback: this.confirmClick })
  },

  beforeDestroy() {
    //Remove the Enter key listener
    if (this.cleanup) {
      this.cleanup()
    }
  }
}
</script>

<style lang="scss">

</style>
<script setup>
</script>