import { render, staticRenderFns } from "./ProjectListOverview.vue?vue&type=template&id=358676e7&scoped=true"
import script from "./ProjectListOverview.vue?vue&type=script&lang=js"
export * from "./ProjectListOverview.vue?vue&type=script&lang=js"
import style0 from "./ProjectListOverview.vue?vue&type=style&index=0&id=358676e7&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "358676e7",
  null
  
)

export default component.exports