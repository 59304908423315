import { BaseClass } from '@/classes/base/BaseClass'


export class ProductClass extends BaseClass {
  constructor (data = {}) {
    super()

    this.id = data.id
    this.api_domain = data.api_domain
    this.created_at = data.created_at
    this.domain = data.domain

    this.is_launcher = data.is_launcher
    this.logo = data.logo
    this.name = data.name
    this.status = data.status
    this.updated_at = data.updated_at

    this.language = this.setLanguage(data)

    this._highestVersion = data._highestVersion ? data._highestVersion : null

    this._installedVersion = data._installedVersion ?  data._installedVersion : null

    this._loadingUpdate = false
    this._updateFinished = false
  }

  // data.language ? data.language : { name: 'English', flag: require('@/assets/img/EN.png'), value: 'en' }

  setLanguage (data) {
    if (this.isOffice) {
      if (data.language) {
        return data.language
      }
      return { name: 'English', flag: require('@/assets/img/EN.png'), value: 'en' }
    }
    return null
  }


  get isOffice () {
    return this.name === 'Office'
  }

  get isSandbox () {
    return this.name === 'Sandbox'
  }
}