const state  = {
  abstractStartLoading: false,
  abstractEndLoading: false,

  floorStartLoading: false,
  floorEndLoading: false,
}

const getters = {
  getaAbstractStartLoading (state) {
    return state.abstractStartLoading
  },

  getAbstractEndLoading (state) {
    return state.abstractEndLoading
  },

  getFloorStartLoading (state) {
    return state.floorStartLoading
  },

  getFloorEndLoading (state) {
    return state.floorEndLoading
  }
}

const mutations = {
  setAbstractStartLoading (state, value) {
    state.abstractStartLoading = value
  },

  setAbstractEndLoading (state, value) {
    state.abstractEndLoading = value
  },

  setFloorStartLoading (state, value) {
    state.floorStartLoading = value
  },

  setFloorEndLoading (state, value) {
    state.floorEndLoading = value
  }
}

const actions = {
  updateAbstractStartLoading (vuexContext, value) {
    vuexContext.commit('setAbstractStartLoading', value)
  },

  updateAbstractEndLoading (vuexContext, value) {
    vuexContext.commit('setAbstractEndLoading', value)
  },

  updateFloorStartLoading (vuexContext, value) {
    vuexContext.commit('setFloorStartLoading', value)
  },

  updateFloorEndLoading (vuexContext, value) {
    vuexContext.commit('setFloorEndLoading', value)
  }
}

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions
}