import { TokenClass } from '@/classes/auth/TokenClass'
import { UserClass } from '@/classes/auth/UserClass'

async function setProductFromSessionStorage (store) {
  const productState = store.getters['product/getSelectedProduct']
  const productSessionStorage = JSON.parse(sessionStorage.getItem('product') )

  if (!productState && productSessionStorage) {
    await store.dispatch('product/updateSelectedProduct', productSessionStorage)
  }
}

async function setProjectFromSessionStorage (store) {
  const projectState = store.getters['project/getSelectedProject']
  const projectSessionStorage = JSON.parse(sessionStorage.getItem('project') )

  if (!projectState && projectSessionStorage) {
    await store.dispatch('project/updateSelectedProject', projectSessionStorage)
  }
}


async function setConfigurationFromSessionStorage (store) {
  const configurationState = store.getters['configuration/getSelectedConfiguration']
  const configurationSessionStorage = JSON.parse(sessionStorage.getItem('configuration') )

  if (!configurationState && configurationSessionStorage) {
    await store.dispatch('configuration/updateSelectedConfiguration', configurationSessionStorage)
  }
}

async function setUserFromSessionStorage (store) {
  const userState = store.getters['auth/getCurrentUser']
  const userSessionStorage = JSON.parse(sessionStorage.getItem('user') )
  const userCookie = UserClass.getUserFromCookie()

  if ((!userState && userSessionStorage) || (!userState && userCookie)) {
    await store.dispatch('auth/updateUser', userSessionStorage ? userSessionStorage : userCookie)
  }
}




export default async function auth ({next, store, nextMiddleware }) {


  await setProductFromSessionStorage(store)
  await setProjectFromSessionStorage(store)
  await setConfigurationFromSessionStorage(store)

  await setUserFromSessionStorage(store)

  if (TokenClass.isNotAuthenticated) {
    return next({
      path: '/start'
    })
  }

  return nextMiddleware()
}
