<template>
  <v-app id="app">
    <router-view />

    <app-snackbar />
  </v-app>
</template>

<script>

import AppSnackbar from '@/components/app/AppSnackbar'

import { mapActions } from 'vuex'

import { v4 as uuidv4 } from 'uuid'
export default {
  name: 'App',
  components: { AppSnackbar },

  data () {
    return {
      lastPath: null,
    }
  },

  methods: {
    ...mapActions({
      updateAbstractStartLoading: 'loading/updateAbstractStartLoading',
      updateAbstractEndLoading: 'loading/updateAbstractEndLoading',
      updateFloorStartLoading: 'loading/updateFloorStartLoading',
      updateFloorEndLoading: 'loading/updateFloorEndLoading'
    }),

    onUnityClose() {
      const key = uuidv4()
      this.$store.dispatch('unity/updateUnityCloseKey', key)

      this.resetLoadingStates()

      if (this.lastPath) {
        this.$router.replace(this.lastPath)
      }
    },

    resetLoadingStates () {
      this.updateAbstractStartLoading(false)
      this.updateAbstractEndLoading(false)
      this.updateFloorStartLoading(false)
      this.updateFloorEndLoading(false)
    },

    onUnityStartLoading (param) {
      switch (param) {
        case 'abstract':
          this.updateAbstractEndLoading(false)
          this.updateAbstractStartLoading(true)
          break

        case 'floor':
          this.updateFloorStartLoading(true)
          this.updateFloorEndLoading(false)
          break
      }
    },

    onUnityEndLoading (param) {
      switch (param) {

        case 'abstract':
          this.updateAbstractStartLoading(false)
          this.updateAbstractEndLoading(true)
          break

        case 'floor':
          this.updateFloorStartLoading(false)
          this.updateFloorEndLoading(true)
          break
      }
    }


  },

  mounted () {
    window['onUnityClose'] = this.onUnityClose

    this.$router.beforeEach((to, from, next) => {
      this.lastPath = from.path
      next();
    })

    window['onUnityEndLoading'] = this.onUnityEndLoading
    window['onUnityStartLoading'] = this.onUnityStartLoading

  }
}
</script>
