<template>
  <app-dialog
    v-model="isDialogVisible"
    :title-prop="title"
    max-width="500"
    v-bind="$attrs"
    v-on="$listeners"
  >
    <div>
      <div>

        <div>
          <h3>
            Agreement on Order Data Processing
          </h3>
          <div class="pl-1 mt-1">
            <p>
              Agreement between the customer ("<strong>Client</strong>") and Die Werkbank IT GmbH
              ("<strong>Contractor</strong>") on
              the processing of personal data on behalf ("<strong>Agreement</strong>").
              Definitions from the main contract between
              Die Werkbank IT GmbH and the customer, including the terms of use, also apply in this agreement.
              Definitions in this Agreement apply only to this Agreement. Terms used in this Agreement shall be
              understood as defined in the EU General Data Protection Regulation (GDPR).
            </p>
          </div>

        </div>

        <div>
          <h3> 1. Subject and duration of the assignment </h3>
          <div class="pl-1">
            <div>
              <h4 class="pl-1 mt-1"> 1.1. Subject of the order </h4>
              <p class="pl-3 mt-1">
                The subject of the order for data handling is the implementation of the tasks of the main contract in
                accordance with Annex 4. The contractor processes personal data for the client within the meaning of
                Article 4 No. 2 and Article 28 GDPR unless otherwise regulated in Section 0.2 below
              </p>

              <h4 class="pl-1">1.2. Delineation of Responsibilities </h4>

              <div class="pl-3 mt-1">
                <p>
                  <strong>Administrative data.</strong> The contractor is responsible within the meaning of Article 4
                  No.
                  7
                  GDPR for the administrative data of the client. The processing of administrative data by
                  Die Werkbank IT GmbH is not the subject of this agreement.
                </p>
                <p>
                  In its data protection declaration made available via the website, Die Werkbank IT GmbH informs
                  the customer about the processing of the customer's personal data for the purpose of managing the
                  customer relationship, including the processing of the customer's administrator's personal data as
                  part of his use of the registration page of the v.create platform ("Administrative Data").
                </p>
                <p>
                  In the data protection declaration on the platform, Die Werkbank IT GmbH informs the customer
                  about the use of identification mechanisms on the registration page of the v.create platform for
                  the customer's administrators. Werkbank IT GmbH is responsible within the meaning of the GDPR for
                  the customer's administrative data.
                </p>
                <p>
                  <strong>Content data.</strong>
                  The contractor hosts all of the client's content data for the client.
                  This means that the contractor processes this content exclusively on behalf of the client.
                </p>
                <p>
                  Content data is all customer content, as well as all other content and data that users
                  (including the customer's administrator) load, enter, automatically upload or exchange on
                  the v.create platform (e.g. names and email addresses, product data or communication with other users)
                  Die Werkbank IT GmbH processes content data for the customer. In this regard, the customer is
                  responsible and Die Werkbank IT GmbH is the processor who acts on the customer's instructions.
                </p>
              </div>
              <h4 class="pl-1">1.3. Duration of Order </h4>
              <p class="pl-3">The duration of this agreement (duration) corresponds to the duration of the contract for
                the provision of the v.create platform. </p>
            </div>

          </div>
        </div>

        <div>
          <h3>2. Specification of the order content </h3>
          <div class="pl-1 mt-1">
            <h4>
              2.1. Scope, Nature and Purpose
            </h4>
            <p class="pl-2">
              The scope, type and purpose of the collection, processing and/or use of personal data by the
              contractor for the client result from the functionality of the v.create platform and the main
              contract to which this contract data processing agreement is attached.
            </p>

            <h4>2.2. Type of Data </h4>
            <p class="pl-2">
              The subject of the collection, processing and/or use of personal data are the following data
              types/categories:
            </p>
            <ul class="pl-7">
              <li>
                Registration data (e.g. picture, first name, last name, title, company, state, email address, country,
                city, date of birth, telephone number, mobile number, address, password, preferred language)
              </li>
              <li>
                Client content data (e.g. product data, calendar information, trade affiliation, team affiliation,
                work orders, actions, photos uploaded by users of the v.create platform)
              </li>
              <li>
                Log data and other metadata (e.g. IP address)
              </li>
              <li>
                Personal data that is collected as part of support requests from users of the v.create platform.
              </li>
            </ul>

            <h4>2.3. Circle of Affected Persons</h4>
            <p class="pl-1 mt-1">The following are affected by the processing:</p>
            <ul class="pl-7">
              <li>Employees of the client</li>
              <li>Employees of the client's customers who use the v.create platform via the client's customer account
              </li>
              <li>Other third parties who are given access to the v.create platform via the client's customer account
              </li>
            </ul>
          </div>
        </div>

        <div class="mt-1">
          <h3>3. Authority of the client / place of data processing </h3>
          <div class="pl-1">
            <p>
              3.1. The data is handled exclusively within the framework of the agreements made and in accordance with
              documented instructions from the client (cf. Article 28(3) lit. a GDPR). Within the scope of the order
              description made in this agreement, the customer reserves the right to give comprehensive instructions
              on the type, scope and procedure of data processing, which he can specify through individual
              instructions. Changes to the object of processing and changes to the process must be coordinated and
              documented together. Additional expenses incurred are to be reimbursed by the client on a time and
              material basis. The contractor may only provide information to third parties or those affected with
              the prior written consent of the client
            </p>
            <p>
              3.2. The customer shall immediately confirm verbal instructions in writing or by e-mail (in text form).
              Insofar as the contractor acts as a processor, he does not use the data for any other purposes and is in
              particular not entitled to pass them on to third parties. Excluded from this are backup copies, insofar as
              they are necessary to ensure proper data processing, as well as data that are necessary with regard to
              compliance with legal obligations under Union law or the law of an EU member state, as well as to comply
              with storage obligations.
            </p>
            <p>3.3. The contractor must inform the client immediately in accordance with Article 28 (3) sentence 3 GDPR
              if he believes that an instruction violates data protection regulations. The contractor is entitled to
              suspend the execution of the corresponding instruction until it is confirmed or changed by the person
              responsible at the client.
            </p>
            <p>3.4. The processing of client data by the contractor generally takes place in the EU/EEA. The contractor
              is obliged to inform the client before starting the processing of a legal obligation of the contractor to
              process the client data at another location, unless such notification is prohibited by law. The processing
              and / or shipment to a third country outside the territory of the EU / EEA or to an international
              organization requires the prior written consent of the client. In this case, the contractor is also
              obliged to ensure an appropriate level of data protection at the place of data processing in accordance
              with the applicable statutory provisions and judicial and official interpretations of the same or - at the
              discretion of the client - to give the client the opportunity to ensure an appropriate level of data
              protection, including by entering into or acceding to EU standard contractual clauses. The client agrees
              to the processing by the subcontractors named in Annex 2 to this agreement outside of the EU / EEA.
            </p>
          </div>
        </div>

        <div>
          <h3> 4. Confidentiality</h3>
          <div class="pl-1">
            <p>The contractor guarantees that the employees involved in the processing of personal data and other
              persons working for the contractor are prohibited from processing the personal data outside of the
              instructions. Furthermore, the contractor guarantees that the persons authorized to process the personal
              data have committed themselves to confidentiality or are subject to an appropriate statutory duty of
              confidentiality. The confidentiality/non-disclosure obligation continues after the end of the order.
            </p>
          </div>
        </div>

        <div>
          <h3>5. Technical and organizational measures </h3>
          <div class="pl-1 mt-1">
            <p>
              5.1. The contractor will design the internal organization in his area of responsibility in such a way that
              it
              meets the special requirements of data protection. He will take appropriate technical and organizational
              measures to protect the client's personal data that meet the requirements of Article 32 GDPR. In
              particular,
              the technical and organizational measures must be taken in such a way that the confidentiality, integrity,
              availability and resilience of the systems and services in connection with data processing are permanently
              ensured. These technical and organizational measures are described in Annex 1 to this agreement. The
              customer is aware of these technical and organizational measures and is responsible for ensuring that they
              offer an appropriate level of protection for the risks of the data to be processed.
            </p>
            <p>5.2. The technical and organizational measures are subject to technical progress and further development.
              In
              this respect, the contractor is permitted to implement alternative, adequate measures. The safety level of
              the specified measures must not be undercut. Significant changes must be documented.</p>
          </div>
        </div>

        <div>
          <h3>6. Subcontracting relationships </h3>
          <div class="pl-1 mt-1">
            <p>
              6.1. The engagement and/or modification of subcontractors by the contractor is possible under the following
              conditions:
            </p>
            <p>6.1.1. The client already agrees to the use of the subcontractors listed in Annex 2 to this
              agreement.
            </p>
            <p>6.1.2. The client agrees to the use or change of other subcontractors if the contractor notifies the
              client in writing (email is sufficient) of the use or change thirty (30) days before the start of data
              processing. The client can object to the use of a new subcontractor or the change. If there is no
              objection within the period, the consent to the use or to the change is deemed to have been given. The
              client acknowledges that in certain cases the service can no longer be provided without the use of a
              specific subcontractor. In these cases, each party is entitled to terminate without observing a notice
              period. If there is an important data protection reason for the objection and it is not possible to find a
              mutually acceptable solution between the contracting parties, the client is granted a special right of
              termination.
            </p>
            <p>6.2. The contractor will design the contractual agreements with the subcontractor(s) in such a way that
              they comply with all data protection regulations, taking into account the type and scope of data
              processing within the subcontract. The Sub-Processor's commitment must be in writing or in electronic
              format.
            </p>
            <p>6.3. Such services are not to be understood as subcontracting relationships within the meaning of this
              provision, which the contractor uses from third parties as ancillary services to support the execution of
              the order. These include, for example, telecommunications services, maintenance and user service, cleaning
              staff, auditors or the disposal of data carriers. However, the contractor is obliged to make appropriate
              and legally compliant contractual agreements and to take control measures to ensure the protection and
              security of the client's data, even if ancillary services are outsourced.
            </p>
          </div>
        </div>

        <div>
          <h3>7. Data subject rights </h3>
          <div class="pl-1 mt-1">
            <p>7.1. The contractor supports the client as far as possible in fulfilling the inquiries and claims of data
              subjects in accordance with Chapter III of the GDPR.
            </p>
            <p>
              7.2. The contractor must only provide information, correct or delete this data or restrict data processing
              accordingly on the instructions of the client about the data that is processed in the order. If a data
              subject should contact the contractor directly for the purpose of information, correction or deletion of
              his/her data and with regard to the restriction of data processing, the contractor will immediately
              forward this request to the client.
            </p>
          </div>
        </div>

        <div>
          <h3>8. Contractor's obligations to cooperate</h3>
          <div class="pl-1 mt-1">
            <p>
              8.1. The contractor supports the client in complying with the obligations referred to in Articles 32 to 36
              GDPR regarding the security of personal data, reporting obligations in the event of data breaches, data
              protection impact assessments and prior consultations.
            </p>
            <p>
              8.2. With regard to any reporting and notification obligations of the client under Articles 33 and 34 GDPR,
              the following applies: The contractor is obliged to inform the client (i) immediately about the breach of
              the protection of personal data and (ii) in the event of such a breach, if necessary to adequately support
              its obligations under Articles 33 and 34 GDPR (Article 28(3) sentence 2 lit. f GDPR). The contractor may
              only carry out reports in accordance with Article 33 or 34 GDPR (reports and notifications in the event of
              a breach of the protection of personal data) for the client following prior instructions in accordance
              with Section 2 of this agreement.
            </p>
            <p>
              8.3. Insofar as the client has notification or notification obligations in the event of a security
              incident, the contractor undertakes to support the client at his expense.
            </p>
          </div>
        </div>

        <div>
          <h3>9. Other Obligations of the Contractor </h3>
          <div class="pl-1 mt-1">
            <p>
              9.1. Insofar as required by law, the contractor appoints a data protection officer who can carry out his
              duties in accordance with Articles 38 and 39 GDPR, §§ 38, 6 BDSG. His contact details will be communicated
              to the client for the purpose of direct contact upon request.
            </p>
            <p>
              9.2. The contractor will inform the client immediately about control actions and measures taken by the
              supervisory authority in accordance with Article 58 GDPR. This also applies if a competent authority
              investigates the contractor in accordance with Article 83 GDPR.
            </p>
            <p>
              9.3. The contractor will ensure that the order is checked by means of regular checks by the contractor with
              regard to the execution or fulfillment of the contract, in particular compliance with and, if necessary,
              adjustment of regulations and measures for the execution of the order.
            </p>
          </div>
        </div>

        <div>
          <h3>10. Client's right to information and verification </h3>
          <div class="pl-1 mt-1">
            <p>
              10.1. The client has the right, pursuant to Article 28(3 ) lit. h GDPR required information to prove
              compliance with the agreed obligations of the contractor and to carry out checks in agreement with the
              contractor or to have them carried out by auditors to be appointed in individual cases.
            </p>
            <p>
              10.2. The parties agree that the contractor is entitled to submit meaningful documentation to the client as
              proof of compliance with his obligations and implementation of the technical and organizational measures.
              Meaningful documentation can be provided by submitting a current attestation, reports or report excerpts
              from independent bodies (e.g. auditors, controllers, data protection officer), suitable certification from
              an IT security or data protection audit (e.g. according to ISO 27001) or certification approved by the
              responsible supervisory authorities be provided.
            </p>
            <p>
              10.3. The customer's right to carry out on-site inspections is not affected by this. However, the client
              will weigh up whether an on-site inspection is still necessary after the submission of meaningful
              documentation, in particular taking into account the maintenance of the contractor's proper operation.
            </p>
            <p>
              10.4. The client has the right to convince himself of the contractor's compliance with this agreement in
              his business operations by means of random checks, which must generally be reported in good time and must
              take place during business hours. The contractor undertakes to provide the client with the information
              required to comply with his obligation to monitor the order and to make the corresponding evidence
              available on request. The contractor reserves the right to charge for expenses that exceed the usual
              amount (approx. 1 day per year).
            </p>
          </div>
        </div>

        <div>
          <h3>11. Deletion of data and return of data carriers </h3>
          <div class="pl-1 mt-1">
            <p>
              11.1. At the discretion and request of the client - at the latest upon termination of the contract - the
              contractor must hand over to the client all documents, processing and usage results created and databases
              that are in connection with the contractual relationship that have come into his possession or, with prior
              consent, in accordance with data protection regulations destroy. The same applies to test and scrap
              material. The log of the deletion must be presented on request.
            </p>
            <p>
              11.2. Documentation that serves as proof of orderly and proper data processing must be retained by the
              contractor in accordance with the respective retention periods beyond the end of the contract period. He
              can hand them over to the client at the end of the contract period to relieve him.
            </p>
          </div>
        </div>

        <div>
          <h3>12. Liability </h3>
          <div class="pl-1 mt-1">
            <p>
              The liability of the parties from this agreement is based on the liability regulations of the terms of
              use.
            </p>
          </div>
        </div>

        <div>
          <h3>13. Directory</h3>
          <div class="pl-1 mt-1">
            <p>
              The contractor is involved in maintaining the register of processing activities (Article 30 GDPR).
            </p>
          </div>
        </div>

        <v-divider class="mb-2" />

        <div>
          <h3>Annex 1 to the order data agreement: </h3>
          <div class="pl-1 mt-1">
            <p>
              Technical and organizational measures according to Article 32 GDPR.
            </p>
            <p>
              As the processor, the contractor has implemented the following technical and organizational security
              measures for the client in order to ensure ongoing confidentiality, integrity, availability and
              reliability of the processing systems and services:
            </p>
          </div>
        </div>

        <div>
          <h3>1. Confidentiality</h3>
          <div class="pl-1 mt-1">
            <p>
              The contractor has taken the following technical and organizational security precautions, in particular to
              ensure the confidentiality of the processing systems and services:
            </p>
            <p>
              1.1. The contractor processes all customer data at server locations operated by industry-leading cloud
              service providers that offer sophisticated measures to protect against unauthorized access to data
              processing facilities (in particular telephones, database and application servers and associated
              hardware).
            </p>
            <p>
              These measures include:
            </p>
            <ul class="pl-7">
              <li>
                A multi-layered security model that includes security measures such as bespoke electronic access cards,
                alarms, vehicle entry barriers, fencing, metal detectors and biometrics, and equipping the data center
                floor with laser intrusion resistance;
              </li>
              <li>
                Data centers are monitored 24/7 by high-resolution indoor and outdoor cameras that can detect and track
                unauthorized persons;
              </li>
              <li>
                Access logs, activity records and camera footage are available in case of a break-in;
              </li>
              <li>
                Data centers are also routinely patrolled by experienced security guards who have undergone rigorous
                background checks and training;
              </li>
              <li>
                Access to the data center floor is only possible via a security corridor, which enables multi-level
                access control using security badges and biometrics;
              </li>
              <li>
                Only authorized employees with certain roles can enter.
              </li>
            </ul>
          </div>

        </div>

        <div class="mt-1">
          <p4>
            1.2. The Contractor shall take appropriate measures to prevent its data processing systems from being used by
            unauthorized persons. This is achieved through:
          </p4>
          <ul class="pl-7 mt-1">
            <li>
              User terminal automatic timeout if left idle, identification and password required to access again;
            </li>
            <li>
              Issuing and securing identification codes to the contractor's online platform, which requires two-factor
              authentication for all users;
            </li>
            <li>
              Customers can define individual user accounts with permissions for all contractor resources;
            </li>
            <li>
              Industry-standard encryption and password requirements (minimum length, use of special characters, etc.);
              and
            </li>
            <li>
              All access to data content is logged, monitored and tracked.
            </li>
            <li>
              The employees of the contractor who are authorized to use his data processing systems can only access
              personal data within the framework and to the extent that is covered by their respective access
              authorization (authorization). Specifically, access rights and levels are based on employee function and
              role, using the concepts of least privilege and need-to-know to align access rights with defined
              responsibilities. This is achieved through:
            </li>
            <li>
              Employee policies and training;
            </li>
            <li>
              Effective and appropriate disciplinary action against anyone who accesses personal data without
              authorization
            </li>
            <li>
              Restricted access to personal data only for authorized persons;
            </li>
            <li>
              Industry standard encryption and
            </li>
            <li>
              Backup Retention Control Policies.
            </li>
          </ul>
        </div>

        <div class="mt-1">
          <h3>2. Integrity </h3>
          <div class="pl-1 mt-1">
            <p>
              The contractor has implemented the following technical and organizational security, in particular to
              ensure the integrity of the processing systems and services:
            </p>
            <p>
              2.1. The contractor shall take appropriate measures to prevent personal data from being read, copied,
              changed or deleted by unauthorized persons during transmission or transport of the data carrier. This is
              achieved through:
            </p>
            <ul class="pl-7 mt-1">
              <li>
                Using the latest firewall and encryption technologies to protect the gateways and pipelines through
                which data flows;
              </li>
              <li>
                Industry standard encryption; and
              </li>
              <li>
                Avoid storing personal data on portable storage media for transportation purposes and on company-owned
                laptops or other mobile devices.
              </li>
            </ul>
            <p>
              2.2. The contractor shall ensure through suitable measures and default settings that the processing only
              takes place for the respective specific processing purpose. This is achieved in particular by:
            </p>
            <ul class="pl-7 mt-1">
              <li>
                The Contractor will not access any Client Content except as necessary to provide the Client with the
                products and professional services selected by the Client. The latter also includes the anonymous
                processing of customer content in order to be able to give the user recommendations based on analyzes
                and algorithms. The contractor does not access customer content for other purposes.
              </li>
            </ul>
          </div>
        </div>

        <div>
          <h3>3. Availability</h3>
          <div class="pl-1 mt-1">
            <p>
              The contractor has implemented the following technical and organizational security measures, in particular
              to ensure the availability of processing systems and services
            </p>
            <ul class="pl-7 mt-1">
              <li>
                The contractor takes appropriate measures to ensure that personal data is protected against accidental
                destruction or loss. This is achieved through:
              </li>
              <li>
                Infrastructure redundancy; and
              </li>
              <li>
                Policies that prohibit permanent local (workplace) storage of personal data.
              </li>
              <li>
                The contractor takes appropriate measures to ensure that identified or suspected security
                incidents/attacks on the IT infrastructure (technical problems, vulnerabilities) can be identified and
                eliminated. This is achieved through:
              </li>
              <li>
                IT incident response management, which includes: active monitoring, service point, vendor support,
                proactive waiting, reactive response to failures;
              </li>
              <li>
                Installed breach response plan;
              </li>
              <li>
                Tracking via ticket system;
              </li>
              <li>
                Training of administration staff.
              </li>
            </ul>
          </div>
        </div>

        <div class="mt-1">
          <h3> 4. Resilience</h3>
          <div class="pl-1 mt-1">
            <p>
              The contractor has implemented the following technical and organizational security measures, in particular
              to ensure the reliability of the processing systems and services:
            </p>
            <ul class="pl-7 mt-1">
              <li>
                The contractor will perform penetration testing and vulnerability assessments, including automated
                system and application security checks on systems used for data processing. The contractor will take
                reasonable steps to avoid any disruption to the services provided.
              </li>
              <li>
                The contractor maintains policies and procedures to assess and control the risks associated with the
                implementation of changes to its services.
              </li>
              <li>
                The contractor keeps an inventory of all IT assets used for the processing activities. In this context,
                the contractor continuously monitors the status and availability of the processing activities.
              </li>
              <li>
                The contractor evaluates the processing activities on business continuity and disaster recovery
                requirements. This includes defined, documented, maintained and validated business continuity and
                disaster recovery plans that conform to industry standard practices.
              </li>
              <li>
                The contractor stores all systems containing personal data in redundant copies and ensures that at least
                one of these copies is located in a separate location from the production systems, encrypts backup data
                stored on portable backup media and verifies the Integrity of this process through regular data recovery
                testing.
              </li>
            </ul>
          </div>
        </div>

        <v-divider class="mb-2 mt-4" />

        <div class="mt-4">
          <h3>Annex 2 to the order data agreement</h3>
          <div class="pl-1 mt-1">
            <p>
              Approved Subcontractors:
            </p>

            <v-simple-table>
              <template v-slot:default>
                <thead>
                <tr>
                  <th class="text-left">
                    #
                  </th>
                  <th class="text-left">
                    Surname
                  </th>
                  <th>
                    address
                  </th>
                  <th>
                    Area of application under the contract
                  </th>
                </tr>
                </thead>
                <tbody>
                <tr>
                  <td>1</td>
                  <td>Next IT Services Ltd</td>
                  <td>58 Bulgaria blvd. , 1680; Sofia, Bulgaria</td>
                  <td>Development of software including data import processes</td>
                </tr>
                <tr>
                  <td>2</td>
                  <td>Amazon Web Services EMEA SARL</td>
                  <td>38 avenue John F. Kennedy, L-1855 Luxembourg</td>
                  <td>AWS Cloud Computing</td>
                </tr>
                <tr>
                  <td>3</td>
                  <td>Atlassian Pty Ltd</td>
                  <td>Level 6, 341 George St, Sydney NSW 2000, Australia</td>
                  <td>Support management, project management and documentation</td>
                </tr>
                </tbody>
              </template>
            </v-simple-table>

          </div>
        </div>

      </div>
    </div>

    <template #actions>
      <v-row>
        <v-spacer></v-spacer>
        <v-col cols="12" sm="3">
          <v-btn
            id="close-dialog-button"
            block
            class="hover-btn-gray"
            color="primary"
            raised
            rounded
            style="text-transform: unset !important;"
            @click.prevent="hideDialog"
          >
            Close
          </v-btn>
        </v-col>
      </v-row>
    </template>
  </app-dialog>
</template>

<script>
import AppDialog from '@/components/app/AppDialog'

export default {
  name: 'AppDialogLegalNotice',
  components: { AppDialog },

  props: {
    value: {
      type: Boolean,
      default: false,
      required: true
    }
  },

  data () {
    return {
      title: 'Legal Notice'
    }
  },

  computed: {
    isDialogVisible: {
      get () {
        return this.value
      },

      set (value) {
        this.$emit('input', value)
      }
    }
  },

  methods: {
    hideDialog () {
      this.isDialogVisible = false
    }
  }

}
</script>

<style lang="scss" scoped>

</style>
