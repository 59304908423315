<template>

  <div>
    <v-card
        :ripple="{ class: 'primary--text' }"
        class="v-card--bordered"
        style="height: 230px"
    >
      <v-img
          :src="entity.logo ? entity.logo.url : ''"
          contain
          class="mx-10"
          height="140"
      />

      <div class="d-flex justify-end pr-2 text--secondary mb-1" style="font-size: 12px">
       <span>
         {{ entity._installedVersion }}
       </span>
        </div>

      <v-divider :style="separatorStyle" />

      <div class="d-flex justify-space-between align-center py-0" style="height: 62px">
        <v-card-subtitle class="py-2">
          <div class="d-flex align-start">
            <div>
              <div class="text-h5 list-item-name my-2">

                <app-update-btn
                    v-if="entity._highestVersion"
                    :ready-to-upgrade-prop="readyToUpdate"
                    :update-finished-prop="entity._updateFinished"
                    :loading-prop="entity._loadingUpdate"
                    :version-prop="nextVersion"
                    @update="updateProduct"
                    :small-btn-prop="true"
                />

              </div>
            </div>
          </div>
        </v-card-subtitle>


        <v-tooltip top color="var(--v-grey-lighten2)" :disabled="isDisabledLangMenu">

          <span class="tooltip-conf-lang">
            Configurator Language
          </span>

          <template #activator="{ on, attrs }">
            <div
                v-bind="attrs"
                v-on="on"
                :class="isDisabledLangMenu ? 'disabled': ''"
            >
              <v-menu
                  v-if="entity.isOffice"
                  :disabled="isDisabledLangMenu"
                  offset-y
                  bottom
                  nudge-bottom="20"
                  nudge-left="20"
              >
                <template v-slot:activator="{ on, attrs }">
                  <v-avatar v-bind="attrs" v-on="on" class="mr-3" height="32" width="32">
                    <img :src="currentSelectedLanguage.flag" alt="flag">
                  </v-avatar>
                </template>

                <v-list>
                  <v-list-item
                      v-for="(language, index) in languages"
                      :key="index"
                      @click="selectLanguage(language)"
                  >
                    <v-list-item-title>{{ language.name }}</v-list-item-title>
                  </v-list-item>
                </v-list>
              </v-menu>
            </div>

          </template>
        </v-tooltip>

      </div>
    </v-card>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import AppUpdateBtn from '@/components/app/AppUpdateBtn.vue'
import { TokenClass } from '@/classes/auth/TokenClass'
import { gameStarted } from '@/utils/gameStart'

export default {
  name: 'ListItemCommonProduct',
  components: { AppUpdateBtn },

  props: {
    entity: {
      type: Object,
      required: true
    },

    selectable: {
      type: Boolean,
      required: false,
      default: false
    },

    minWidthProp: {
      type: String,
      default: '249px'
    },

  },

  data () {
    return {
      selectedLanguage: null,

      languages: [
        { name: 'English', flag: require('@/assets/img/EN.png'), value: 'en' },
        { name: 'German', flag: require('@/assets/img/DE.png'), value: 'de' },
      ],

      readyToUpdate: true
    }
  },

  computed: {
    ...mapGetters(
        {
          selectedProduct: 'product/getSelectedProduct',
          products: 'product/getProducts'
        }),

    currentSelectedLanguage () {
      if (this.entity && this.selectedProduct && this.selectedProduct.language) {
        return this.selectedProduct.language
      }
      return { name: 'English', flag: require('@/assets/img/EN.png'), value: 'en' }
    },

    userToken () {
      return TokenClass.userToken
    },

    nextVersion () {
      if (this.entity._highestVersion) {
        return this.entity._highestVersion.version
      }
      return null
    },

    separatorStyle() {
      return {
        'margin-top': this.entity && this.entity._installedVersion ? '0px' : '22px'
      };
    },

    isDisabledLangMenu () {
      if (this.selectedProduct && this.entity.id === this.selectedProduct.id && this.entity.isOffice) {
        return false
      }
      return true
    }
  },

  methods: {
    selectLanguage (language) {
      this.$store.dispatch('product/updateSelectedProductLang', language)
    },

    async updateProduct () {
      try {
        const isGameStarted = gameStarted(this.entity.name)

        if (isGameStarted) {
          await this.$store.dispatch('snackbar/showSnackbarMessage', {
            message: `Please close ${this.entity.name}, then you will able to update`,
            duration: 9000,
            mode: 'fail'
          })
        } else {
          this.$emit('setUpdatingProduct', this.entity)

          this.$emit('changeLoading', true)

          this.readyToUpdate = false


          const payload = {
            "GameName" : this.entity.name,
            "AccessToken" : this.userToken,
            "DownloadLink" : this.entity._highestVersion.versionUrl
          }

          window.vcreateInterface.install(JSON.stringify(payload))
        }
      } catch (error) {
        console.log(error)
        this.$error(error)
      }
    }
  },
}
</script>

<style lang="scss" scoped>
@import '~vuetify/src/styles/styles.sass';

.v-card {
  &__subtitle {
    padding-right: 10px;
  }

  .select-checkbox {
    opacity: 0;
    visibility: hidden;
    transform: translateX(5px);
    transition: transform $secondary-transition,
    opacity $secondary-transition,
    visibility $secondary-transition;
  }

  .list-item-name {
    color: var(--v-grey-darken1);
    width: auto;
    overflow: hidden;
    white-space: nowrap;
    font-size: 0.875rem !important;

    .theme--light.v-btn {
      color: var(--v-grey-darken1);

    }
  }

  &:hover,
  &.selected {
    .select-checkbox {
      opacity: 1;
      visibility: visible;
      transform: translateX(0px);
    }

    .list-item-name {
      color: var(--v-primary-base)
    }
  }
}


.fake-button {
  border: 1px solid var(--v-grey-darken2);
  border-radius: 24px;
  width: 100px;
  margin-top: 2px;
  .content {
    text-align: center;
    font-size: 12px;
  }
}

.tooltip-conf-lang {
  color: var(--v-grey-darken1);
}

.disabled {
  opacity: 0.3;
}
</style>
