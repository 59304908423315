export const enterKeyListenerMixin  = {

  data () {
    return {
      cleanUp: null
    }
  },

  methods: {
    toggleEnterKeyListener({ isDisabled, callback }) {
      if (isDisabled && this.cleanup) {
        // Remove listener if button is disabled
        this.cleanup()
        this.cleanup = null
      } else if (!isDisabled && !this.cleanup) {
        // Add listener only if button is not disabled and listener isn't already set
        this.cleanup = this.$enterKey.addListener(callback)
      }
    }
  }
}


