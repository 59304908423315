import AuthService from '@/services/auth.service'

import { TokenClass } from '@/classes/auth/TokenClass'
import { UserClass } from '@/classes/auth/UserClass'


const state = () => ({
  user: null
})

const getters = {
  getCurrentUser (state) {
    return state.user
  }
}

const mutations = {
  setUser (state, user) {
    state.user = user
    sessionStorage.setItem('user', JSON.stringify(user))
  },

  signOut (state) {
    state.user = null

    TokenClass.removeRegularToken()
    TokenClass.removeRememberToken()
    UserClass.removeUserCookie()
  },

  setUserProfile (state, roles) {
    state.user._roles = roles
  }
}

const actions = {
  async signOut (vuexContext) {
    vuexContext.commit('signOut')
  },

  async login (vuexContext, data) {
    const response = await AuthService.login(data)

    const user = new UserClass(response.user)

    if (data.isRememberMe) {
      TokenClass.setRememberToken(response.access_token)
      vuexContext.commit('setUser', user)
      UserClass.setUserInCookie(user)
      return
    }

    TokenClass.setRegularToken(response.access_token)
    vuexContext.commit('setUser', user)
  },

  async fetchUserProfile (vuexContext, { slug, api_domain }) {

    try {
      if (!vuexContext.state.user) {
        const responseUser = await AuthService.getUser( {
          slug,
          productApiDomain:api_domain,
        })
        vuexContext.commit('setUser', new UserClass(responseUser))
      }

      const userProfile = await AuthService.getUserProfile({
        slug,
        productApiDomain:api_domain,
        profileId: vuexContext.state.user.id
      })

      vuexContext.commit('setUserProfile', userProfile.roles)

    } catch (e) {
      vuexContext.commit('setUserProfile', null)
      console.log(e)
      throw e
    }
  },

  updateUser (vuexContext, user) {
    vuexContext.commit('setUser', new UserClass(user))
  }

}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}
