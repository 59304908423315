<template>
  <div class="pt-4">
    <v-container class="list-container pa-0 ">
      <v-row>
        <v-col
            class="pt-0 no-gutters"
            cols="12"
            lg="4"
            md="12"
        >
          <v-card
              v-if="selectedProject"
              :ripple="false"
              class="v-card--bordered"
              height="500"
          >
            <v-img
                :src="getSelectedProjectImage"
                height="400"
            />
            <v-card-subtitle class="pa-8">
              <div class="d-flex align-center" style="height: 55px">
                <div>
                  <div class="text-h5 list-item-name selected-project-name">
                    {{ selectedProject.name }}

                  </div>

                  <div class="text-h5 list-item-name">
                  </div>
                </div>

                <v-spacer></v-spacer>

                <v-img
                    id="conf-step-project-logo"
                    :src="selectedProject.logo ? selectedProject.logo.url : ''"
                    contain
                    max-height="50"
                    max-width="100"
                    position="left"
                    style="cursor:pointer;"
                    v-bind="$attrs"
                    v-on="$listeners"
                />
              </div>
            </v-card-subtitle>
          </v-card>
        </v-col>

        <v-col
            class="pt-0 pb-1 no-gutters"
            cols="12"
            lg="8"
            md="12"
            v-if="confLoading"
        >
          <app-loader-listing/>
        </v-col>

        <v-col
            v-else
            class="pt-0 pb-1 no-gutters"
            cols="12"
            lg="8"
            md="12"
            style="overflow-y:auto; height:500px;"
        >
          <div>
            <v-card
                v-if="selectedProject"
                :class="highlightSelectedProjectClass(newConfigurationId)"
                :ripple="false"
                class="v-card--bordered d-flex align-center pl-5 cursor-pointer configuration-selection new-conf-create"
                @click="changeSelectedConfiguration(newConfObj, true)"
            >
              <v-card-subtitle class="pr-2 pl-0 d-flex align-center new-conf-container" style="height: 62px">
                <div class="d-flex align-center list-item-name">
                  <div class="configuration-selection__icon mr-5">
                  </div>

                  <div class="text-h5">
                    New configuration
                  </div>
                </div>
              </v-card-subtitle>
            </v-card>
          </div>

          <v-divider class="mt-5"/>

          <div
              v-for="(configuration, index) in configurations"
              :key="`configuration-${configuration.id}-${index}`"
          >
            <v-card
                v-if="selectedProject"
                :class="highlightSelectedProjectClass(configuration.id)"
                :ripple="false"
                class="v-card--bordered d-flex align-center mt-5 pl-5 cursor-pointer configuration-selection configuration-holder"
                @click="changeSelectedConfiguration(configuration)"
            >

              <v-card-subtitle class="py-2 pr-2 pl-0" style="width: 100vw">
                <div class="d-flex align-center list-item-name justify-space-between">
                  <div class="d-flex justify-start align-center">
                    <div class="configuration-selection__icon mr-5">
                    </div>

                    <div class="d-flex align-start flex-column">
                      <div class="text-h5">
                        {{ configuration.name }}
                      </div>

                      <div
                          class="d-flex align-center text-subtitle-2 info-text"
                          style="gap: 20px;"
                      >
                        <div>
                          Date created: {{ $timeToLocal(configuration.created_at)}}
                        </div>

                        <div>
                          Last time modified: {{ $timeToLocal(configuration.updated_at) }}
                        </div>

                        <div v-if="selectedProduct.name !== 'Office'">
                          Modules:
                          <template
                              v-if="configuration && configuration.latest_save && configuration.latest_save.modules > 0">
                            {{ configuration.latest_save.modules }}
                          </template>

                          <template v-else>
                            0
                          </template>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div @click.prevent.stop="showDeleteConfDialog(configuration)" style="float: right" class="bin-icon">
                    <v-hover>
                      <template v-slot="{ hover }">
                        <app-svg
                            class="mr-6 delete-rule-set-btn"
                            :color="hover ? 'primary' : 'var(--v-grey-darken2)'"
                            src="@/assets/img/icons/bin_icon.svg"
                            size="20"
                        />
                      </template>
                    </v-hover>
                  </div>
                </div>
              </v-card-subtitle>
            </v-card>
          </div>

          <div v-if="!configurations || configurations.length === 0">
            <configuration-or-template-step-overview-empty-list :msg-prop="emptyListTitle"/>
          </div>
        </v-col>
      </v-row>
    </v-container>

    <div class="d-flex justify-center mt-5">
      <v-btn
          id="configuration-back-button"
          class="hover-outlined-btn-gray"
          color="primary"
          min-width="96px"
          outlined
          rounded
          @click.native="backClick"
      >
        Back
      </v-btn>

      <v-btn
          id="launch-configuration-bnt"
          :disabled="isDisabledLaunchConfBtn"
          :loading="isLaunchButtonLoading"
          class="ml-5 hover-btn-gray"
          color="primary"
          rounded
          @click.native="openStartGame"
          :key="keyLaunchBtn"
      >
        Launch configuration
      </v-btn>
    </div>

    <configuration-delete-dialog
        v-if="isDeleteConfDialogVisible"
        v-model="isDeleteConfDialogVisible"
        :key="keyDeleteConfDialog"
        :configuration-prop="confForDelete"
        @fetchProjectConfigurations="getAllConfigurations"
    />

    <configuration-create-dialog
        v-if="isNewConfDialogVisible"
        v-model="isNewConfDialogVisible"
        :key="keyNewConfDialog"
        @startGame="openStartGame"
    />

  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import { actionStates } from '@/constants'
import { v4 as uuidv4 } from 'uuid'
import { cloneDeep } from 'lodash'

import ConfigurationOrTemplateStepOverviewEmptyList
  from '@/components/configuration/ConfigurationOrTemplateStepOverviewEmptyList.vue'
import ConfigurationDeleteDialog from '@/components/configuration/dialog/ConfigurationDeleteDialog.vue'
import ConfigurationCreateDialog from '@/components/configuration/dialog/ConfigurationCreateDialog.vue'
import AppSvg from '@/components/app/AppSvg.vue'

import { TokenClass } from '@/classes/auth/TokenClass'
import AppLoaderListing from '@/components/app/AppLoaderListing.vue'

import { gameStarted } from '@/utils/gameStart'

import { enterKeyListenerMixin } from '@/mixins/enterKeyListenerMixin'

export default {
  name: 'ConfigurationStepOverviewContainer',
  components: {
    AppLoaderListing,
    ConfigurationOrTemplateStepOverviewEmptyList,
    ConfigurationDeleteDialog,
    ConfigurationCreateDialog,
    AppSvg
  },

  mixins: [enterKeyListenerMixin],

  data () {
    return {
      newConfigurationId: uuidv4(),
      actionStateConstants: actionStates,
      isLaunchButtonLoading: false,

      confForDelete: null,
      isDeleteConfDialogVisible: false,
      keyDeleteConfDialog: uuidv4(),

      isNewConfDialogVisible: false,
      keyNewConfDialog: uuidv4(),

      keyLaunchBtn: uuidv4(),

      originalConfigurations: null,

      emptyListTitle: 'No saved configurations available yet'
    }
  },

  computed: {
    ...mapGetters(
        {
          currentState: 'configuration/getCurrentState',
          configurations: 'configuration/getConfigurations',
          selectedConfiguration: 'configuration/getSelectedConfiguration',
          selectedProduct: 'product/getSelectedProduct',
          selectedProject: 'project/getSelectedProject',
          selectedOrganization: 'organization/getSelectedOrganization',
          unityCloseKey: 'unity/getUnityCloseKey'
        }
    ),

    confLoading () {
      return this.currentState === this.actionStateConstants.LOADING
    },

    isDisabledLaunchConfBtn () {
      if (this.isNewConfDialogVisible || this.isDeleteConfDialogVisible) {
        return true
      }
      return this.isLaunchButtonLoading || !this.selectedConfiguration || this.isGameStarted() || this.confLoading
    },

    newConfObj () {
      const newConfObjStrategy = [
        { name: 'Sandbox', newConfObj: {id: this.newConfigurationId} },

        { name: 'Office', newConfObj: {id: ''} }
      ]

      return newConfObjStrategy.find(item => item.name === this.selectedProduct.name).newConfObj
    },

    userToken () {
      return TokenClass.userToken
    },

    getSelectedProjectImage () {
      if (this.selectedProject.image) {
        return this.selectedProject.image.url
      }
      else if ( this.selectedProject.picture ) {
        return this.selectedProject.picture.url
      }
      else {
        return ''
      }
    },

  },

  watch: {
    unityCloseKey () {
      this.onUnityClose()
    },

    isDisabledLaunchConfBtn (newVal) {
      this.toggleEnterKeyListener({isDisabled: newVal, callback: this.openStartGame })
    }
  },

  methods: {
    payloadStartGame () {
      const payloadStrategy = [
        {
          name: 'Sandbox',
          payload: {
            'ProjectId': this.selectedProject.id,
            'AccessToken': this.userToken,
            'OrganizationSlug': this.selectedOrganization.slug,
            'ConfigurationId': this.selectedConfiguration.id,
            'ConfigurationSaveId': this.selectedConfiguration.latest_save ? this.selectedConfiguration.latest_save.id : null,
            'GameName': this.selectedProduct.name,
            'TemplateId': '',
            // This must be dynamic when lifestyle is implemented
            'GameMode': 'Configurator'
          }
        },

        {
          name: 'Office',
          payload: {
            'AccessToken': this.userToken,
            'OrganizationId' : this.selectedOrganization.id,
            'OrganizationName': this.selectedOrganization.name,
            'OrganizationOwnerId': this.selectedOrganization.owner_id,
            'OrganizationSlug': this.selectedOrganization.slug,
            'ProjectId' : this.selectedProject.id,
            'TechnicalProjectId': this.selectedProject.technical_project_id,
            'ClientName': this.selectedProject.name,
            'SaveName': this.selectedConfiguration.name,
            'SaveCode': this.selectedConfiguration.latest_save ? this.selectedConfiguration.latest_save.id : '',
            'GameName': this.selectedProduct.name,
            'Lang': this.selectedProduct.language?.value,
            'TemplateId': '',
            // This must be dynamic when lifestyle is implemented
            'GameMode': 'Configurator'
          }
        }
      ]

      return payloadStrategy.find(item => item.name === this.selectedProduct.name).payload
    },

    hrefWithParamsStartGame () {
      let latestSaveId = null
      if (this.selectedConfiguration &&
          this.selectedConfiguration.latest_save &&
          this.selectedConfiguration.latest_save.id) {
        latestSaveId = this.selectedConfiguration.latest_save.id
      }

      const hrefWithParamsStrategy = [
        {
          name: 'Sandbox',
          hrefWithParams: `startGame?` +
              `OrganizationSlug=${this.selectedOrganization.slug}&` +
              `ProjectId=${this.selectedProject.id}&` +
              `AccessToken=${this.userToken}&` +
              `ConfigurationId=${this.selectedConfiguration.id}&` +
              `GameName=${this.selectedProduct.name}&` +
              `ConfigurationSaveId=${latestSaveId}&`+
              `TemplateId=''&`+
              // This must be dynamic when lifestyle is implemented
              `GameMode=Configurator`
        },

        {
          name: 'Office',
          hrefWithParams: `startGame?` +
              `AccessToken=${this.userToken}&` +
              `OrganizationId=${this.selectedOrganization.id}&` +
              `OrganizationName=${this.selectedOrganization.name}&` +
              `OrganizationOwnerId=${this.selectedOrganization.owner_id}&` +
              `OrganizationSlug=${this.selectedOrganization.slug}&` +
              `ProjectId=${this.selectedProject.id}&` +
              `TechnicalProjectId=${this.selectedProject.technical_project_id}&` +

              `Lang=${this.selectedProduct.language?.value}&` +

              `GameName=${this.selectedProduct.name}&` +
              `ClientName=${this.selectedProject.name}&` +
              `SaveName=${this.selectedConfiguration.name}&` +
              `SaveCode=${this.selectedConfiguration.latest_save ? this.selectedConfiguration.latest_save.id : ''}&` +
              `TemplateId=''&`+
              `GameMode=Configurator`
        }
      ]
      return hrefWithParamsStrategy.find(item => item.name === this.selectedProduct.name).hrefWithParams
    },

    async showDeleteConfDialog (conf) {
      this.confForDelete = conf
      this.isDeleteConfDialogVisible = true
      this.keyDeleteConfDialog = uuidv4()
    },

    async getAllConfigurations () {
      await this.$store.dispatch('configuration/fetchAllConfigurations', {
        projectId: this.selectedProject.id,
        slug: this.selectedOrganization.slug.toString(),
        api_domain: this.selectedProduct.api_domain.toString()
      })

      this.originalConfigurations = cloneDeep(this.configurations)

    },

    async openStartGame () {
      console.log(this.payloadStartGame(), 'payload')

      this.isLaunchButtonLoading = true

      try {
        if (this.newConfigurationId === this.selectedConfiguration.id) {
          await this.$store.dispatch('configuration/createConfiguration', {
            projectId: this.selectedProject.id,
            configurationId: this.newConfigurationId,
            slug: this.selectedOrganization.slug.toString(),
            api_domain: this.selectedProduct.api_domain.toString()
          })

          this.newConfigurationId = uuidv4()
        }

        // New Launcher
        if (window.vcreateInterface && window.vcreateInterface.startGame) {
          const payload = this.payloadStartGame()

          window.vcreateInterface.startGame(JSON.stringify(payload))
        } else {
          // Old Launcher
          window.location.href = this.hrefWithParamsStartGame()
        }

        setTimeout(() => {
          this.$nextTick(() => {
            this.isLaunchButtonLoading = false
          })
        }, 5000)

        await this.$router.push('/loading')

        this.isGameStarted()
      } catch (e) {
        this.$error(e)
        this.isLaunchButtonLoading = false
      }
    },

    isGameStarted () {
      return gameStarted(this.selectedProduct.name)
    },

    async changeSelectedConfiguration (configuration, createNewConf = false) {
      await this.$store.dispatch('configuration/updateSelectedConfiguration', configuration)

      if (this.selectedProduct.isOffice && createNewConf) {
        if (this.isGameStarted()) {
          await this.$store.dispatch('snackbar/showSnackbarMessage', {
            message: `Please close ${this.selectedProduct.name}, then you will able to launch new configuration`,
            duration: 9000,
            mode: 'fail'
          })
          return
        }

        this.isNewConfDialogVisible = true

        this.keyNewConfDialog = uuidv4()
      }
    },

    highlightSelectedProjectClass (entityId) {
      if (this.selectedConfiguration && this.selectedConfiguration.id) {
        return (entityId === this.selectedConfiguration.id) ? 'configuration-selection__active-item' : ''
      }
    },

    async selectNewConfigurationIfItWasSelected () {
      if (
          this.selectedProduct.name === 'Sandbox' &&
          this.selectedConfiguration &&
          this.selectedConfiguration.id &&
          !this.selectedConfiguration.name
      ) {

        const newConfiguration = {id: this.newConfigurationId}

        await this.$store.dispatch('configuration/updateSelectedConfiguration', newConfiguration)
        sessionStorage.setItem('configuration', JSON.stringify(newConfiguration))
      }
    },

    async onUnityClose () {
      await this.getAllConfigurations()

      const newCurrentConf = this.configurations.find(item => item.id === this.selectedConfiguration.id)
      if (newCurrentConf) {
        await this.changeSelectedConfiguration(newCurrentConf)
      }
      this.keyLaunchBtn = uuidv4()
    },

    async backClick () {
      sessionStorage.removeItem('configuration')
      await this.$store.dispatch('configuration/removeSelectedConfiguration')
      await this.$router.push('/project')
    },

    async makeSearch (searchInput) {
      if (searchInput && searchInput.length >2) {
        const foundItems =  this.$search({
          items: this.originalConfigurations,
          searchKey: searchInput,
        })

        await this.$store.dispatch('configuration/updateConfigurations', foundItems)
        this.emptyListTitle = 'Nothing Found'

      } else {
        await this.$store.dispatch('configuration/updateConfigurations', this.originalConfigurations)
        this.emptyListTitle = 'No saved configurations available yet'
      }
    }
  },

  async mounted () {
    await this.getAllConfigurations()
    await this.selectNewConfigurationIfItWasSelected()

    // Register the Enter key listener
    this.toggleEnterKeyListener({isDisabled: this.isDisabledLaunchConfBtn, callback: this.openStartGame })
  },


  beforeDestroy() {
    if (this.cleanup) {
      this.cleanup()
    }
  }

}
</script>

<style lang="scss" scoped>
.list-container {
  width: 75%;
  @media only screen and (max-width: 1900px) {
    width: 100%;
    padding: 0;
  }

  /* width */
  ::-webkit-scrollbar {
    width: 4px;
  }

  /* Track */
  ::-webkit-scrollbar-track {
    background: var(--v-grey-lighten2);
    border-radius: 100px;
  }

  /* Handle */
  ::-webkit-scrollbar-thumb {
    background: var(--v-primary-base);
    border-radius: 100px;
  }
}

.list-item-name {
  color: var(--v-grey-darken1);
  overflow: hidden;
  line-height: normal;

  .theme--light.v-btn {
    color: var(--v-grey-darken1);
  }

  .info-text {
    color: var(--v-grey-lighten1);
  }
}

:deep(.v-card--link:focus:before) {
  opacity: 0 !important;
}

.configuration-selection {
  &__icon {
    border: 2px solid var(--v-grey-darken2);
    border-radius: 100%;
    width: 24px;
    height: 24px;
  }

  &:hover {
    .configuration-selection__icon {
      border: 2px solid var(--v-primary-base);
    }
  }

  &__active-item {
    border: 2px solid;
    border-color: var(--v-primary-base);

    .configuration-selection__icon {
      border: 5px solid var(--v-primary-base) !important;
      border-radius: 100%;
      width: 24px;
      height: 24px;
    }
  }
}

</style>
