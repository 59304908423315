<template>
  <v-app>
    <div class="background-section d-flex justify-center align-center">
      <v-card class="py-10 px-md-5 px-3" rounded="0" style=" background: transparent; box-shadow: none;" min-width="25%">
        <v-card-title class="pa-0 mb-5">
          <v-img
            id="app-logo-image"
            :aspect-ratio="3.25"
            :src="getLoginImageURL()"
            alt="Logo"
            class="logo mx-auto"
            contain max-height="70" max-width="300"
          />
        </v-card-title>

        <transition :key="$router.currentRoute.path" mode="out-in" name="slide-fade">
          <router-view></router-view>
        </transition>
      </v-card>
    </div>

    <div v-if="$router.currentRoute.path === '/start'">
      <div
        class="legal-notes ma-4 ma-sm-15 cursor-pointer"
        @click="openLegalNoticeDialog"
      >
        Legal Notice
      </div>

<!--      <div-->
<!--        class="version-notes ma-4 ma-sm-15 cursor-pointer"-->
<!--        @click="openVersionDialog"-->
<!--      >-->
<!--        Version 2.2.0-->
<!--      </div>-->

      <app-dialog-version
        v-if="showVersionDialog"
        :key="`version-${dialogKey}`"
        v-model="showVersionDialog"
      />

      <app-dialog-legal-notice
        v-if="showLegalNoticeDialog"
        :key="`legal-notice-${dialogKey}`"
        v-model="showLegalNoticeDialog"
      />
    </div>
  </v-app>
</template>

<script>
import AppDialogVersion from '@/components/app/AppDialogVersion'
import AppDialogLegalNotice from '@/components/app/AppDialogLegalNotice'

export default {
  name: "TheAuthLayoutWrap",
  components: { AppDialogLegalNotice, AppDialogVersion },

  data () {
    return {
      dialogKey: 0,
      showVersionDialog: false,
      showLegalNoticeDialog: false
    }
  },

  methods: {
    getLoginImageURL () {
      return require('@/assets/img/sandbox-logo.png')
    },

    openVersionDialog () {
      this.dialogKey++
      this.showVersionDialog = true
    },

    openLegalNoticeDialog () {
      this.dialogKey++
      this.showLegalNoticeDialog = true
    }
  }
}
</script>

<style lang="scss" scoped>
.background-section {
  width: 100%;
  height: 100%;
  background-size: cover;
  background: #F7F7F7 0 0 no-repeat padding-box;
}

.slide-fade-enter-active {
  transition: all .5s ease;
}

.slide-fade-leave-active {
  transition: all .5s cubic-bezier(1.0, 0.5, 0.8, 1.0);
}

.slide-fade-enter {
  transform: translateY(100px);
  opacity: 0;
}

.legal-notes {
  position: absolute;
  bottom: 0;
  left: 0;
  color: #909090;
}

.version-notes {
  position: absolute;
  bottom: 0;
  right: 0;
  color: #909090;
}
</style>
