import $axios from '@/plugins/axios'
import { BackEndServiceEnvironment } from '@/classes/service/BackEndServiceEnvironment'

class ProjectService {
  getAllProjects (slug, productApiDomain) {

    const correctProductApDomain = BackEndServiceEnvironment.getBackEndServiceEnvironment(productApiDomain)

    const baseProjectURL = `${ location.protocol }//${ slug }.${ correctProductApDomain }/api/v1`

    return $axios.get(`${ baseProjectURL }/get-projects`)
      .then((res) => {
        return res.data
      })
      .catch((error) => {
        throw error
      })
  }

  getProjectConfigurations (projectId, slug, productApiDomain) {
    const correctProductApDomain = BackEndServiceEnvironment.getBackEndServiceEnvironment(productApiDomain)

    const baseProjectURL = `${ location.protocol }//${ slug }.${ correctProductApDomain }/api/v1`

    return $axios.get(`${ baseProjectURL }/get-project-configurations/${ projectId }`)
      .then((res) => {
        return res.data
      })
      .catch((error) => {
        throw error
      })
  }

  getOfficeProjectUnitySaveTemplates ({ projectId, slug, productApiDomain }) {
    const correctProductApDomain = BackEndServiceEnvironment.getBackEndServiceEnvironment(productApiDomain)

    const baseProjectURL = `${ location.protocol }//${ slug }.${ correctProductApDomain }/api/v1`

    return $axios.get(`${ baseProjectURL }/get-project-unity-saves-templates/?project_id=${ projectId }`)
      .then((res) => {
        return res.data
      })
      .catch((error) => {
        throw error
      })
  }

  deleteOfficeProjectUnitySaveTemplate ({ slug, productApiDomain, templateId }) {
    const correctProductApDomain = BackEndServiceEnvironment.getBackEndServiceEnvironment(productApiDomain)

    const baseProjectURL = `${ location.protocol }//${ slug }.${ correctProductApDomain }/api/v1`

    return $axios.post(`${ baseProjectURL }/delete-unity-save-template`, {
      id: templateId,
    })
      .then((res) => {
        return res.data
      })
      .catch((error) => {
        throw error
      })
  }

  createConfiguration (projectId, configurationId, slug, productApiDomain) {
    const correctProductApDomain = BackEndServiceEnvironment.getBackEndServiceEnvironment(productApiDomain)

    const baseProjectURL = `${ location.protocol }//${ slug }.${ correctProductApDomain }/api/v1`

    return $axios.post(`${ baseProjectURL }/create-configuration`, {
      id: configurationId,
      name: 'Untitled configuration',
      project_id: projectId
    })
      .then((res) => {
        return res.data
      })
      .catch((error) => {
        throw error
      })
  }

  hardDeleteConfiguration ({ configurationId, slug, productApiDomain }) {
    const correctProductApDomain = BackEndServiceEnvironment.getBackEndServiceEnvironment(productApiDomain)

    // console.log('REMOVE ')
    // productApiDomain = 'local.dreso-dev.link:8082'

    const baseProjectURL = `${ location.protocol }//${ slug }.${ correctProductApDomain }/api/v1`

    return $axios.post(`${ baseProjectURL }/hard-delete-configuration`, { id: configurationId })
      .then((res) => {
        return res.data
      })
      .catch((error) => {
        throw error
      })
  }

}

export default new ProjectService()
