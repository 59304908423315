<template>
  <div>
    <project-list-overview
      :is-list-loading-prop="projectsLoading"
      :projects-prop.sync="projects"
      :selected-project-prop.sync="selectedProject"
      @selectProject="selectProject"
    />

    <div class="d-flex justify-center mt-5">
      <v-btn
        id="project-back-button"
        class="hover-outlined-btn-gray"
        color="primary"
        min-width="96px"
        outlined
        rounded
        @click.native="backClick"
      >
        Back
      </v-btn>

      <v-btn
        id="project-continue-button"
        :disabled="isDisabledContinueBtn"
        class="ml-5 hover-btn-gray"
        color="primary"
        rounded
        width="122px"
        @click.native="continueClick"
      >
        Continue
      </v-btn>
    </div>
  </div>
</template>

<script>
import ProjectListOverview from '@/components/project/ProjectListOverview'

import { mapGetters } from 'vuex'
import { actionStates } from '@/constants'

import { enterKeyListenerMixin } from '@/mixins/enterKeyListenerMixin'

export default {
  name: "ProjectStepOverviewContainer",

  components: { ProjectListOverview },

  mixins: [enterKeyListenerMixin],

  data () {
    return {
      actionStateConstants: actionStates
    }
  },

  computed: {
    ...mapGetters(
      {
        selectedOrganization: 'organization/getSelectedOrganization',
        selectedProduct: 'product/getSelectedProduct',
        projects: 'project/getProjects',
        currentState: 'project/getCurrentState',
        selectedProject: 'project/getSelectedProject'
      }
    ),

    projectsLoading () {
      return this.currentState === this.actionStateConstants.LOADING
    },

    isDisabledContinueBtn () {
      if (!this.selectedProject || this.projectsLoading) {
        return true
      }
      return false
    }
  },

  watch: {
    isDisabledContinueBtn (newVal) {
      this.toggleEnterKeyListener({isDisabled: newVal, callback: this.continueClick })
    }
  },


  methods: {
    async getAllProjects () {
      try {
        const payload = {
          slug: this.selectedOrganization.slug.toString(),
          api_domain: this.selectedProduct.api_domain.toString()
        }

        await this.$store.dispatch('project/fetchAllProjects', payload)

      } catch (e) {
        this.$error(e)
      }
    },

    removePreviousSelections () {
      this.$store.dispatch('configuration/removeSelectedConfiguration')
    },

    async selectProject (project) {
      this.removePreviousSelections()
      await this.$store.dispatch('project/updateSelectedProject', project)
    },

    async continueClick () {
      await this.$router.push('/configuration')
    },

    async backClick () {
      sessionStorage.removeItem('project')
      await this.$store.dispatch('project/removeSelectedProject')
      await this.$router.push('/product')
    },


  },

  async mounted () {
    await this.getAllProjects()
    // Register the Enter key listener
    this.toggleEnterKeyListener({isDisabled: this.isDisabledContinueBtn, callback: this.continueClick })
  },

  beforeDestroy() {
    if (this.cleanup) {
      this.cleanup()
    }
  }

}
</script>

<style scoped>

</style>
