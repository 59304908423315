<template>
  <div class="pt-4">
    <v-container class="list-container pa-0 ">
      <v-row>
        <v-col
            class="pt-0 no-gutters"
            cols="12"
            lg="4"
            md="12"
        >
          <v-card
              :ripple="false"
              class="v-card--bordered"
              height="500"
          >
            <div v-if="!selectedTemplate" class="skeleton-loader-container">
              <v-skeleton-loader
                  boilerplate
                  type="card, list-item-three-line"
                  height="500"
                  class="custom-conf-template-skeleton-loader"
              />
              <div class="skeleton-loader-text">Select a template to preview content</div>
            </div>
            <v-img
                v-else
                :src="selectedTemplate.image"
                height="400"
            />
            <v-card-subtitle class="pa-8" v-if="selectedTemplate">
              <div class="d-flex align-center" style="height: 55px">
                <div>
                  <div class="text-h5 list-item-name selected-project-name">
                    {{ selectedTemplate.description }}
                  </div>
                </div>
              </div>
            </v-card-subtitle>
          </v-card>
        </v-col>

        <v-col
            class="pt-0 pb-1 no-gutters"
            cols="12"
            lg="8"
            md="12"
            v-if="loading"
        >
          <app-loader-listing/>
        </v-col>

        <v-col
            class="pt-0 pb-1 no-gutters"
            cols="12"
            lg="8"
            md="12"
            style="overflow-y:auto; height:500px;"
            v-else
        >

          <div
              v-for="(template, index) in templates"
              :key="`template-${template.id}-${index}`"
          >
            <v-card
                v-if="selectedProject"
                class="v-card--bordered d-flex align-center mt-5 pl-5 cursor-pointer template-selection template-holder"
                :ripple="false"
                :class="highlightSelectedTemplateClass(template.id)"
                @click="changeSelectedTemplate(template)"
            >

              <v-card-subtitle class="py-2 pr-2 pl-0" style="width: 100vw">
                <div class="d-flex align-center list-item-name justify-space-between">
                  <div class="d-flex justify-start align-center">
                    <div class="template-selection__icon mr-5">
                    </div>

                    <div class="d-flex align-start flex-column">
                      <div class="text-h5">
                        {{ template.name }}
                      </div>

                      <div
                          class="d-flex align-center text-subtitle-2 info-text"
                          style="gap: 20px;"
                      >
                        <div>
                          Date created: {{ $timeToLocal(template.created_at) }}
                        </div>

                        <div>
                          Last time modified: {{ $timeToLocal(template.updated_at) }}
                        </div>
                      </div>
                    </div>
                  </div>

                  <div @click.prevent.stop="showDeleteTemplateDialog(template)" style="float: right" class="bin-icon">
                    <v-hover>
                      <template v-slot="{ hover }">
                        <app-svg
                            class="mr-6 delete-rule-set-btn"
                            :color="hover ? 'primary' : 'var(--v-grey-darken2)'"
                            src="@/assets/img/icons/bin_icon.svg"
                            size="20"
                        />
                      </template>
                    </v-hover>
                  </div>

                </div>
              </v-card-subtitle>
            </v-card>
          </div>

          <div v-if="!templates || templates.length === 0">
            <configuration-or-template-step-overview-empty-list :msg-prop="emptyListTitle"/>
          </div>

        </v-col>
      </v-row>
    </v-container>

    <div class="d-flex justify-center mt-5">
      <v-btn
          id="configuration-back-button"
          class="hover-outlined-btn-gray"
          color="primary"
          min-width="96px"
          outlined
          rounded
          @click.native="backClick"
      >
        Back
      </v-btn>

      <v-btn
          id="launch-template=btn"
          class="ml-5 hover-btn-gray"
          color="primary"
          rounded
          :disabled="isDisabledLaunchTemplateBtn"
          :loading="isLaunchButtonLoading"
          @click.native="showTemplateNameDialog"
          :key="keyLaunchBtn"
      >
        Launch template
      </v-btn>
    </div>

    <configuration-template-delete-dialog
        v-if="isDeleteTemplateDialogVisible"
        v-model="isDeleteTemplateDialogVisible"
        :key="keyDeleteTemplateDialog"
        :template-prop="templateForDelete"
        @getTemplates="getAllTemplates"
        @removeSelectedTemplate="changeSelectedTemplate(null)"
    />

    <configuration-template-name-dialog
        v-if="isConfTemplateNameDialogVisible"
        v-model="isConfTemplateNameDialogVisible"
        :key="keyConfTemplateNameDialog"
        @startGame="openStartGame"
    />

  </div>
</template>


<script>
import AppSvg from '@/components/app/AppSvg.vue'
import ConfigurationOrTemplateStepOverviewEmptyList
  from '@/components/configuration/ConfigurationOrTemplateStepOverviewEmptyList.vue'
import ConfigurationTemplateDeleteDialog from '@/components/configuration/dialog/ConfigurationTemplateDeleteDialog.vue'
import AppLoaderListing from '@/components/app/AppLoaderListing.vue'
import ConfigurationTemplateNameDialog from '@/components/configuration/dialog/ConfigurationTemplateNameDialog.vue'


import ProjectService from '@/services/project.service'

import { mapGetters } from 'vuex'
import { v4 as uuidv4 } from 'uuid'
import { cloneDeep } from 'lodash'

import { TokenClass } from '@/classes/auth/TokenClass'
import { ConfigurationTemplateClass } from '@/classes/ConfigurationTemplates'

import { gameStarted } from '@/utils/gameStart'

import { enterKeyListenerMixin } from '@/mixins/enterKeyListenerMixin'


export default {
  name: 'ConfigurationTemplateStepOverviewContainer',

  components: {
    AppLoaderListing,
    ConfigurationOrTemplateStepOverviewEmptyList,
    AppSvg,
    ConfigurationTemplateDeleteDialog,
    ConfigurationTemplateNameDialog
  },

  mixins: [enterKeyListenerMixin],

  data () {
    return {
      templates: null,
      selectedTemplate: null,

      loading: false,

      isLaunchButtonLoading: false,

      templateForDelete: null,
      isDeleteTemplateDialogVisible: false,
      keyDeleteTemplateDialog: uuidv4(),

      isConfTemplateNameDialogVisible: false,
      keyConfTemplateNameDialog: uuidv4(),

      keyLaunchBtn: uuidv4(),

      originalTemplates: null,
      emptyListTitle: 'No saved templates available yet'
    }
  },

  computed: {
    ...mapGetters(
        {
          selectedProduct: 'product/getSelectedProduct',
          selectedProject: 'project/getSelectedProject',
          selectedOrganization: 'organization/getSelectedOrganization',
          unityCloseKey: 'unity/getUnityCloseKey'
        }
    ),

    userToken () {
      return TokenClass.userToken
    },

    isDisabledLaunchTemplateBtn () {
      if (this.isDeleteTemplateDialogVisible || this.isConfTemplateNameDialogVisible) {
        return true
      }

      return !this.selectedTemplate || this.isLaunchButtonLoading || this.isGameStarted() || this.loading
    }
  },

  watch: {
    unityCloseKey () {
      this.onUnityClose()
    },

    isDisabledLaunchTemplateBtn (newVal) {
      this.toggleEnterKeyListener({isDisabled: newVal, callback: this.showTemplateNameDialog})
    }
  },

  methods: {
    isGameStarted () {
      return gameStarted(this.selectedProduct.name)
    },

    showTemplateNameDialog () {
      this.isConfTemplateNameDialogVisible = true
      this.keyConfTemplateNameDialog = uuidv4()
    },

    changeSelectedTemplate (template) {
      this.selectedTemplate = template
    },

    highlightSelectedTemplateClass (entityId) {
      if (this.selectedTemplate && this.selectedTemplate.id) {
        return (entityId === this.selectedTemplate.id) ? 'template-selection__active-item' : ''
      }
    },
    showDeleteTemplateDialog (template) {
      this.templateForDelete = template
      this.isDeleteTemplateDialogVisible = true
      this.keyDeleteTemplateDialog = uuidv4()
    },


    async backClick () {
      await this.$router.push('/project')
    },

    async getAllTemplates () {
      try {
        this.loading = true
        const templatesResponse = await ProjectService.getOfficeProjectUnitySaveTemplates({
          projectId: this.selectedProject.id,
          slug: this.selectedOrganization.slug.toString(),
          productApiDomain: this.selectedProduct.api_domain.toString()
        })

        this.templates = ConfigurationTemplateClass.createList(templatesResponse)
        this.originalTemplates = cloneDeep(this.templates)

      } catch (e) {
        this.templates = []
        this.selectedTemplate = null
        this.$error(e)
      } finally {
        this.loading = false
      }
    },

    makeSearch (searchInput) {
      if (searchInput && searchInput.length > 2) {
        this.templates = this.$search({
          items: this.originalTemplates,
          searchKey: searchInput
        })
        this.emptyListTitle = 'Nothing Found'
      } else {
        this.templates = this.originalTemplates
        this.emptyListTitle = 'No saved templates available yet'
      }
    },

    async onUnityClose () {
      await this.getAllTemplates()
      this.keyLaunchBtn = uuidv4()
    },

    async openStartGame (confName) {
      console.log(this.payloadStartGame(), 'payload')

      this.isLaunchButtonLoading = true

      try {
        // New Launcher
        if (window.vcreateInterface && window.vcreateInterface.startGame) {
          const payload = this.payloadStartGame(confName)

          window.vcreateInterface.startGame(JSON.stringify(payload))
        } else {
          // Old Launcher
          window.location.href = this.hrefWithParamsStartGame(confName)
        }

        setTimeout(() => {
          this.$nextTick(() => {
            this.isLaunchButtonLoading = false
          })
        }, 5000)

        await this.$router.push('/loading')

        this.isGameStarted(this.selectedProduct.name)
      } catch (e) {
        this.$error(e)
        this.isLaunchButtonLoading = false
      }
    },
    payloadStartGame (confName) {
      return {
        'AccessToken': this.userToken,
        'OrganizationId': this.selectedOrganization.id,
        'OrganizationName': this.selectedOrganization.name,
        'OrganizationOwnerId': this.selectedOrganization.owner_id,
        'OrganizationSlug': this.selectedOrganization.slug,
        'ProjectId': this.selectedProject.id,
        'TechnicalProjectId': this.selectedProject.technical_project_id,
        'ClientName': this.selectedProject.name,
        'SaveName': confName,
        'SaveCode': '',
        'GameName': this.selectedProduct.name,
        'Lang': this.selectedProduct.language?.value,
        'TemplateId': this.selectedTemplate.id,
        'GameMode': 'Configurator'
      }
    },

    hrefWithParamsStartGame (confName) {
      return `startGame?` +
          `AccessToken=${this.userToken}&` +
          `OrganizationId=${this.selectedOrganization.id}&` +
          `OrganizationName=${this.selectedOrganization.name}&` +
          `OrganizationOwnerId=${this.selectedOrganization.owner_id}&` +
          `OrganizationSlug=${this.selectedOrganization.slug}&` +
          `ProjectId=${this.selectedProject.id}&` +
          `TechnicalProjectId=${this.selectedProject.technical_project_id}&` +

          `Lang=${this.selectedProduct.language?.value}&` +

          `GameName=${this.selectedProduct.name}&` +
          `ClientName=${this.selectedProject.name}&` +
          `SaveName=${confName}&` +
          `SaveCode=''&` +
          `TemplateId=${this.selectedTemplate.id}&` +
          // This must be dynamic when lifestyle is implemented
          `GameMode=Configurator`
    }
  },

  async mounted () {
    await this.getAllTemplates()

    // Register the Enter key listener
    this.toggleEnterKeyListener({
      isDisabled: this.isDisabledLaunchTemplateBtn,
      callback: this.showTemplateNameDialog
    })
  },

  beforeDestroy() {
    if (this.cleanup) {
      this.cleanup()
    }
  }
}
</script>


<style lang="scss" scoped>
.list-container {
  width: 75%;
  @media only screen and (max-width: 1900px) {
    width: 100%;
    padding: 0;
  }

  /* width */
  ::-webkit-scrollbar {
    width: 4px;
  }

  /* Track */
  ::-webkit-scrollbar-track {
    background: var(--v-grey-lighten2);
    border-radius: 100px;
  }

  /* Handle */
  ::-webkit-scrollbar-thumb {
    background: var(--v-primary-base);
    border-radius: 100px;
  }
}

.list-item-name {
  color: var(--v-grey-darken1);
  overflow: hidden;
  line-height: normal;

  .theme--light.v-btn {
    color: var(--v-grey-darken1);
  }

  .info-text {
    color: var(--v-grey-lighten1);
  }
}

:deep(.v-card--link:focus:before) {
  opacity: 0 !important;
}

.template-selection {
  &__icon {
    border: 2px solid var(--v-grey-darken2);
    border-radius: 100%;
    width: 24px;
    height: 24px;
  }

  &:hover {
    .template-selection__icon {
      border: 2px solid var(--v-primary-base);
    }
  }

  &__active-item {
    border: 2px solid;
    border-color: var(--v-primary-base);

    .template-selection__icon {
      border: 5px solid var(--v-primary-base) !important;
      border-radius: 100%;
      width: 24px;
      height: 24px;
    }
  }
}


.skeleton-loader-container {
  position: relative;
}

.skeleton-loader-text {
  position: absolute;
  top: 50%; /* center vertically */
  left: 50%; /* center horizontally */
  transform: translate(-50%, -50%);
  color: #999; /* greyed out text color */
}

</style>