import $axios from '@/plugins/axios'
import { BackEndServiceEnvironment } from '@/classes/service/BackEndServiceEnvironment'

const baseAuthURL = `${ location.protocol }//${ process.env.VUE_APP_API_OMT_BASE_URL }/api/v1`

class AuthService {
  login (user) {
    return $axios.post(`${ baseAuthURL }/login`, { email: user.email, password: user.password })
      .then((res) => {
        return res.data
      })
      .catch((error) => {
        throw error
      })
  }

  getUserProfile ({ slug, productApiDomain, profileId }) {
    const correctProductApDomain = BackEndServiceEnvironment.getBackEndServiceEnvironment(productApiDomain)

    const baseProjectURL = `${ location.protocol }//${ slug }.${ correctProductApDomain }/api/v1`

    return $axios.get(`${ baseProjectURL }/get-profile?id=${profileId}`)
      .then((res) => {
        return res.data
      })
      .catch((error) => {
        throw error
      })
  }

  getUser ({ slug, productApiDomain }) {
    const correctProductApDomain = BackEndServiceEnvironment.getBackEndServiceEnvironment(productApiDomain)

    const baseProjectURL = `${ location.protocol }//${ slug }.${ correctProductApDomain }/api/v1`

    return $axios.get(`${ baseProjectURL }/get-user`)
      .then((res) => {
        return res.data
      })
      .catch((error) => {
        throw error
      })
  }


}

export default new AuthService()
