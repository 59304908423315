const state = {
  unityCloseKey: null
}

const getters = {
  getUnityCloseKey (state) {
    return state.unityCloseKey
  }
}


const mutations = {
  setUnityCloseKey (state, key) {
    state.unityCloseKey = key
  },
}

const actions = {
  updateUnityCloseKey (vuexContext, key) {
    vuexContext.commit('setUnityCloseKey', key)
  }
}

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions
}
