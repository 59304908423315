<template>
  <div>
    <product-list-overview
        :is-list-loading-prop="productsLoading"
        :products-prop.sync="products"
        :selected-product-prop.sync="selectedProduct"
        @selectProduct="selectProduct"
    />

    <div class="d-flex justify-center mt-5">
      <v-btn
          id="product-back-button"
          class="hover-outlined-btn-gray"
          color="primary"
          min-width="96px"
          outlined
          rounded
          @click.native="backClick"
          :disabled="anyProductUpdatingLoading"
      >
        Back
      </v-btn>

      <v-btn
          id="product-continue-button"
          :disabled="isDisabledContinueBtn"
          class="ml-5 hover-btn-gray"
          color="primary"
          rounded
          width="122px"
          @click.native="continueClick"
      >
        Continue
      </v-btn>
    </div>
  </div>
</template>

<script>
import ProductListOverview from '@/components/product/ProductListOverview'

import { mapGetters } from 'vuex'
import { actionStates } from '@/constants'

import { enterKeyListenerMixin } from '@/mixins/enterKeyListenerMixin'

export default {
  name: 'ProductStepOverviewContainer',

  components: { ProductListOverview },

  mixins: [enterKeyListenerMixin],

  data () {
    return {
      actionStateConstants: actionStates,
      mustUpdate: false,
    }
  },

  computed: {
    ...mapGetters(
        {
          products: 'product/getProducts',
          currentState: 'product/getCurrentState',
          selectedProduct: 'product/getSelectedProduct',
          selectedOrganization: 'organization/getSelectedOrganization',
          anyProductUpdatingLoading: 'product/getAnyProductUpdatingLoading',
          previousRoute: 'router/getPreviousRoute'
        }
    ),

    productsLoading () {
      return this.currentState === this.actionStateConstants.LOADING
    },

    isDisabledContinueBtn () {
      return !this.selectedProduct || this.mustUpdate|| this.anyProductUpdatingLoading || this.productsLoading
    }
  },

  watch: {
    isDisabledContinueBtn (newVal) {
      this.toggleEnterKeyListener({isDisabled: newVal, callback: this.continueClick })
    },

    selectedProduct: {
      immediate: true,
      handler () {
        this.setMustUpdate()
      }
    }
  },

  methods: {
    setMustUpdate () {
      if (
          this.selectedProduct &&
          this.selectedProduct._highestVersion &&
          this.selectedProduct._highestVersion.mandatory_users
      ) {
        this.mustUpdate = true
      } else {
        this.mustUpdate = false
      }
    },

    removePreviousSelections () {
      this.$store.dispatch('project/removeSelectedProject')
      this.$store.dispatch('configuration/removeSelectedConfiguration')
    },

    async selectProduct (product) {
      this.removePreviousSelections()
      await this.$store.dispatch('product/updateSelectedProduct', product)

    },

    async getAllProducts () {
      if (this.previousRoute !== '/skip') {
        await this.$store.dispatch('product/fetchAllProducts', this.selectedOrganization.slug)
      }

    },

    async continueClick () {
      await this.$router.push('/project')
    },

    async backClick () {
      sessionStorage.removeItem('product')
      await this.$store.dispatch('product/removeSelectedProduct')
      await this.$router.push('/organization')
    }
  },

  async mounted () {
    await this.getAllProducts()

    // Register the Enter key listener
    this.toggleEnterKeyListener({isDisabled: this.isDisabledContinueBtn, callback: this.continueClick })
  },

  beforeDestroy() {
    if (this.cleanup) {
      this.cleanup()
    }
  }
}
</script>

<style scoped>

</style>
