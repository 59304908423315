import Vue from 'vue'
import VueRouter from 'vue-router'
import guest from '@/router/middleware/guest'
import auth from '@/router/middleware/auth'
import organization from '@/router/middleware/organization'

import AppAuthLayoutContent from '@/AppAuthLayoutContent'
import AuthLoginView from '@/views/auth/AuthLoginView'
import AuthStartView from '@/views/auth/AuthStartView'
import AppLayoutContent from '@/AppLayoutContent'
import OrganizationView from '@/views/OrganizationView'
import middlewarePipeline from '@/router/middlewarePipeline'
import ProductView from '@/views/ProductView'
import ProjectView from '@/views/ProjectView'
import ConfigurationView from '@/views/ConfigurationView'
import UpdateView from '@/views/UpdateView.vue'
import SkipOrgAndApp from '@/views/SkipOrgAndApp.vue'
import LoadingView from '@/views/LoadingView.vue'

import store from '@/store'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    component: AppLayoutContent,
    children: [
      {
        path: '',
        redirect: '/update'
      },

      {
        path: '/skip',
        component: SkipOrgAndApp,
        meta: {
          middleware: [auth]
        }
      },

      {
        path: '/organization',
        component: OrganizationView,
        meta: {
          middleware: [auth, organization]
        }
      },

      {
        path: '/product',
        component: ProductView,
        meta: {
          middleware: [auth, organization]
        }
      },

      {
        path: '/project',
        component: ProjectView,
        meta: {
          middleware: [auth, organization]
        }
      },

      {
        path: '/configuration',
        component: ConfigurationView,
        meta: {
          middleware: [auth, organization]
        }
      }
    ]
  },

  {
    path: '/update',
    component: AppAuthLayoutContent,
    children: [
      {
        path: '/update',
        component: UpdateView,
        meta: {
          middleware: [auth]
        }
      },
    ]
  },

  {
    path: '/loading',
    component: LoadingView,
    meta: {
      middleware: [ auth, organization ]
    }
  },

  {
    path: '/start',
    component: AppAuthLayoutContent,
    children: [
      {
        path: '/start',
        component: AuthStartView,
        meta: {
          middleware: [guest]
        }
      },
      {
        path: '/login',
        component: AuthLoginView,
        meta: {
          middleware: [guest]
        }
      }
    ]
  }
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

router.beforeEach(async (to, from, next) => {

  await store.dispatch('router/updatePreviousRoute', from.fullPath);

  if (!to.meta.middleware) {
    return next()
  }

  const middleware = to.meta.middleware
  const context = {to, from, next, store}

  return middleware[0]({ ...context, nextMiddleware: middlewarePipeline(context, middleware, 1) })
})

export default router
