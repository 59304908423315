import ProductService from '@/services/product.service'

import { actionStates } from '@/constants'
import { getHighestBeVersion } from '@/utils/versionCheck'

const state  = {
  highestVersion: null,
  currentState: actionStates.INITIAL,
}

const getters = {
  getHighestVersion (state) {
    return state.highestVersion
  },

  getCurrentState (state) {
    return state.currentState
  },

  getShowUpdateView (state) {
    if (state.highestVersion && (state.highestVersion.active || state.highestVersion.mandatory_users)) {
      return true
    }
    return false
  },

  getUpdateUrl (state) {
    if (state.highestVersion) {
      if (state.highestVersion.windows_file_id) {
        return state.highestVersion.windows_file_id.url
      }
      return state.highestVersion.macos_file_id.url
    }
      return null
  }
}


const mutations = {
  setActionState (state, actionState) {
    state.currentState = actionState
  },

  setHighestVersion (state, highestVersion) {
    state.highestVersion = highestVersion
  }
}


const actions = {
  async decideHighestVersion (vuexContext) {
    vuexContext.commit('setActionState', actionStates.LOADING)

    // eslint-disable-next-line no-useless-catch
    try {
      const launcherProductsResponse = await ProductService.getLauncherProducts()

      if (launcherProductsResponse.length) {
        const launcher = launcherProductsResponse[0]
        const beVersionResponse = await ProductService.getConfiguratorReleases(launcher.id)

        if (window.vcreateInterface) {
          let launcherVersions = null

          if (window.vcreateInterface.getInstalledSoftware) {
            launcherVersions = JSON.parse(window.vcreateInterface.getInstalledSoftware())
          }

            const highestVersion = getHighestBeVersion({
              beVersions: beVersionResponse,
              installedVersion: launcherVersions ? launcherVersions['Launcher'] : null
            })

            if (highestVersion) {
              vuexContext.commit('setHighestVersion', highestVersion)
            } else {
              vuexContext.commit('setHighestVersion', null)
            }

        }
        vuexContext.commit('setActionState', actionStates.DATA_LOADED)
      }

    } catch (error) {
      console.log(error)
      vuexContext.commit('setHighestVersion', null)
      if (error && error.response && error.response.status === 404) {
        vuexContext.commit('setActionState', actionStates.NOT_FOUND)
      } else {
        vuexContext.commit('setActionState', actionStates.ERRORED)
      }
    }
  }
}


export { actionStates }


export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions
}