<template>
  <div>
    <div class="d-flex justify-center align-center flex-column">

      <v-progress-circular
          class="mt-12 progress-bar"
          indeterminate
          color="primary"
          size="52"
          v-if="currentState === actionStateConstants.LOADING"
      ></v-progress-circular>

      <div v-else class="update-container">
        <div v-if="launcherHighestVersion && launcherHighestVersion.version">

          <div>
            <transition :key="$router.currentRoute.path" mode="out-in" name="scale">
              <div>
                <div class="text-h3 text--secondary d-flex justify-center">
                  Update available!
                </div>

                <div class="version-container text-small text--secondary mt-4">
                  Version {{ launcherHighestVersion.version }} is available. Please download and install the new update.
                </div>

                <div class="d-flex flex-row justify-center mt-12">
                  <app-update-btn
                      v-if="launcherHighestVersion"
                      :product-version-prop="launcherHighestVersion.version"
                      :ready-to-upgrade-prop="readyToUpdate"
                      :update-finished-prop="updateFinished"
                      :loading-prop="loading"
                      @update="updateLauncher"
                      class="update-btn"
                  />

                  <v-btn
                      v-if="!mandatory && !loading"
                      large
                      small
                      outlined
                      rounded
                      class="ml-3 skip-btn"
                      color="primary"
                      style="text-transform: unset !important;"
                      @click.prevent="redirect"
                  >
                    Skip
                  </v-btn>
                </div>
              </div>
            </transition>
          </div>
        </div>


      </div>
    </div>
  </div>
</template>

<script>
import AppUpdateBtn from '@/components/app/AppUpdateBtn.vue'

import { mapGetters } from 'vuex'
import { actionStates } from '@/constants'
import { TokenClass } from '@/classes/auth/TokenClass'

export default {
  name: 'UpdateView',
  components: { AppUpdateBtn },
  data () {
    return {

      readyToUpdate: true,

      updateFinished: false,

      loading: false,

      actionStateConstants: actionStates,

    }
  },

  computed: {
    ...mapGetters(
        {
          launcherHighestVersion: 'launcher/getHighestVersion',
          updateUrl: 'launcher/getUpdateUrl',
          currentState: 'launcher/getCurrentState',
          showUpdateView: 'launcher/getShowUpdateView',
          versionUrl: 'launcher/getUpdateUrl'
        }
    ),

    mandatory () {
      if (this.launcherHighestVersion) {
        return this.launcherHighestVersion.mandatory_users
      }
      return false
    },

    userToken () {
      return TokenClass.userToken
    }
  },

  watch: {
    async currentState (newV, oldV) {
      if (oldV === actionStates.LOADING && newV === actionStates.DATA_LOADED && !this.showUpdateView) {
        await this.redirect()
      }
    }
  },

  methods: {
    async updateLauncher () {
      try {
        this.loading = true
        this.readyToUpdate = false

        const payload = {
          'GameName': 'Launcher',
          'AccessToken': this.userToken,
          'DownloadLink': this.updateUrl
        }

        await window.vcreateInterface.install(JSON.stringify(payload))

      } catch (error) {
        console.log(error)
        this.$error(error)
      }
    },

    async decideHighestVersion () {
      try {
        await this.$store.dispatch('launcher/decideHighestVersion')
      } catch (e) {
        this.$error(e)
      }
    },

    async redirect () {
      await this.$router.push('/skip')
    }
  },

  async created () {
    await this.decideHighestVersion()

    window['errorInstallLauncher'] = (error) => {
      console.log(error)
    }
  }
}
</script>

<style lang="scss" scoped>
.update-btn-content-wrapper {
  display: flex;
  gap: 10px;
  justify-content: center;
  align-items: center;

  .dot {
    height: 10px;
    width: 10px;
    background-color: var(--v-success2-base);
    border-radius: 50%;
  }
}

.url-link {
  color: #0B57D0;
}

.color-primary {
  color: white;
  background-color: var(--v-primary-base);
}


</style>
