<template>
  <div class="app-navigation-wrap">
    <v-row no-gutters>
      <v-col
        cols="12"
        lg="4"
        md="5"
      >
        <div class="d-flex justify-space-between">
          <div
            :class="pageProgress === 25 ? 'font-weight-bold' : ''"
            :style="anyProductUpdatingLoading ? 'opacity: 0.5;' : ''"
            class="cursor-pointer text-h3"
            @click="goToPreviousSteps('/organization', 25)"
          >
            Organization
          </div>

          <div
            :class="pageProgress === 50 ? 'font-weight-bold' : ''"
            :style="pageProgress < 50 ? 'opacity: 0.5;' : ''"
            class="cursor-pointer text-h3"
            @click="goToPreviousSteps('/product', 50)"
          >
            Application
          </div>

          <div
            :class="pageProgress === 75 ? 'font-weight-bold' : ''"
            :style="pageProgress < 75 ? 'opacity: 0.5;' : ''"
            class="cursor-pointer text-h3"
            @click="goToPreviousSteps('/project', 75)"
          >
            Project
          </div>

          <div
            :class="pageProgress === 100 ? 'font-weight-bold' : ''"
            :style="pageProgress < 100 ? 'opacity: 0.5;' : ''"
            class="cursor-pointer text-h3"
            @click="goToPreviousSteps('/configuration', 100)"
          >
            Configuration
          </div>
        </div>
      </v-col>
    </v-row>

    <v-row class="pt-4" no-gutters>
      <v-col
        cols="12"
        lg="4"
        md="5"
      >
        <v-progress-linear :value="pageProgress"></v-progress-linear>
      </v-col>
    </v-row>
  </div>
</template>

<script>
export default {
  name: "TheDefaultLayoutWrapAppNavigation",

  computed: {
    pageProgress () {
      let route = this.$router.currentRoute

      if (route.path === '/organization') return 25
      if (route.path === '/product') return 50
      if (route.path === '/project') return 75
      if (route.path === '/configuration') return 100

      return 0
    },

    anyProductUpdatingLoading () {
      return this.$store.getters['product/getAnyProductUpdatingLoading']
    }
  },

  methods: {
    async goToPreviousSteps (pagePath, pageStep) {
      if (this.pageProgress > pageStep && !this.anyProductUpdatingLoading) {
        await this.$router.push(pagePath)
      }
    }
  }
}

</script>

<style lang="scss" scoped>

.app-navigation-wrap {
  padding-top: 20px;
  padding-left: 80px;
}

@media only screen and (max-width: 960px) {
  .app-navigation-wrap {
    padding: 20px;
  }
}

:deep(.v-progress-linear__background.primary) {
  background-color: #ddd !important;
}
</style>
