<template>
  <div
        class="d-flex justify-center align-center flex-column"
        ref="focusElement"
    >
      <div class="pt-11 text-h3 text-center text--disabled ">
        Welcome to v.create!
      </div>
      <div style="padding-top: 100px; ">
        <transition :key="$router.currentRoute.path" mode="out-in" name="scale">
          <v-btn
            id="start-btn"
            class="hover-outlined-btn-gray"
            color="primary"
            outlined
            raised
            rounded
            style="text-transform: unset !important; text-align: center;"
            width="125"
            @click="redirectLogin"
          >
            Continue
          </v-btn>
        </transition>
      </div>
    </div>
</template>

<script>
import { actionStates } from '@/constants'

export default {
  name: 'AuthStartView',

  data () {
    return {
      actionStateConstants: actionStates,
    }
  },

  computed: {
    currentState () {
      return this.$store.getters['launcher/getCurrentState']
    }
  },

  methods: {
    redirectLogin() {
      this.$router.push('/login')
    },
  },

 async mounted() {
    // Register the Enter key listener
    this.cleanup = await this.$enterKey.addListener(this.redirectLogin)
  },

  beforeDestroy() {
    //Remove the Enter key listener
    this.cleanup()
  }
}
</script>

<style lang="scss" scoped>
  .update-btn-content-wrapper {
    display: flex;
    gap: 10px;
    justify-content: center;
    align-items: center;

    .dot {
      height: 10px;
      width: 10px;
      background-color: var(--v-success2-base);
      border-radius: 50%;
    }
  }


</style>
