<template>
  <app-dialog
    v-model="isDialogVisible"
    :title-prop="title"
    max-width="500"
    v-bind="$attrs"
    v-on="$listeners"
  >
    <ul v-for="(item, i) in bulletPoints" :key="i">
      <li>{{ item }}</li>
    </ul>

    <template #actions>
      <v-row>
        <v-spacer></v-spacer>
        <v-col cols="12" sm="3">
          <v-btn
            id="close-dialog-button"
            block
            class="hover-btn-gray"
            color="primary"
            raised
            rounded
            style="text-transform: unset !important;"
            @click.prevent="hideDialog"
          >
            Close
          </v-btn>
        </v-col>
      </v-row>
    </template>
  </app-dialog>
</template>

<script>
import AppDialog from '@/components/app/AppDialog'

export default {
  name: 'AppDialogInfoVersion',
  components: { AppDialog },

  props: {
    value: {
      type: Boolean,
      default: false,
      required: true
    }
  },

  data () {
    return {
      title: 'Version',
      bulletPoints: [
        'Background model',
        'Automatic low-poly tree replacement',
        'Better outlines and low detail mode (blue outlines)',
        'Better snapping and placement',
        'Error handling: no internet connection',
        'Gamification of FPV: Jump, Sprint and Colliders',
        'Info window',
        'Bug fixing'
      ]
    }
  },

  computed: {
    isDialogVisible: {
      get () {
        return this.value
      },

      set (value) {
        this.$emit('input', value)
      }
    }
  },

  methods: {
    hideDialog () {
      this.isDialogVisible = false
    }
  }

}
</script>

<style lang="scss" scoped>

</style>
