import store from '@/store'


const beServiceMap  = [
  {
    env: 'dev',
    scope: 'office',
    apiDomain: 'wmsvcv1-dev-main.swarm.dreso-dev.link',
    localHost: 'local.dreso-dev.link:8083'
  },

  {
    env: 'dev',
    scope: 'sandbox',
    apiDomain: 'vcsbsvc-dev-main.swarm.dreso-dev.link',
    localHost: 'local.dreso-dev.link:8082'
  },

  {
    env: 'qa',
    scope: 'office',
    apiDomain: 'wmsvcv1-qa.swarm.dreso-dev.link',
    localHost: 'local.dreso-dev.link:8083'
  },

  {
    env: 'qa',
    scope: 'sandbox',
    apiDomain: 'vcsbsvc-qa.swarm.dreso-dev.link',
    localHost: 'local.dreso-dev.link:8082'
  },
]


export class BackEndServiceEnvironment {

  static getBackEndServiceEnvironment (apiDomain) {
    // TODO make unit tests for this because these are hardcoded environments

    if (process.env.NODE_ENV === 'development') {
      const localHostDomain = beServiceMap.find(item => item.apiDomain === apiDomain)

      if (localHostDomain) {
        return localHostDomain.localHost
      }

      store.dispatch('snackbar/showSnackbarMessage', {
        message: 'API domain not found in beServiceMap. Please check if the api domains have been updated and the map is accurate.',
        duration: 9000,
        mode: 'fail'
      })
      throw new Error("API domain not found in beServiceMap. Please check if the api domains have been updated and the map is accurate.")

    }
    return apiDomain
  }
}