<template>
  <div>
    <h3 class="mb-6 text-center text-h3 " style="color: #909090;">
      <div v-if="projects.length > 0">
        Select and preview a project
      </div>
      <div v-else>
        No projects available yet
      </div>
    </h3>

    <project-step-overview-container/>
  </div>
</template>

<script>

import ProjectStepOverviewContainer from '@/components/project/ProjectStepOverviewContainer'

export default {
  name: "ProjectView",
  components: { ProjectStepOverviewContainer },

  computed: {
    projects () {
      return this.$store.getters['project/getProjects']
    }
  }
}
</script>

<style scoped>

</style>
