import VueCookies from 'vue-cookies'
import { cookieExpireTime } from '@/constants'

export class UserClass {
  constructor (data = {}) {
    this.company = data.company
    this.company_position = data.company_position
    this.created_at = data.created_at
    this.deleted_at = data.deleted_at
    this.description = data.description
    this.email = data.email
    this.email_verified_at = data.email_verified_at
    this.image = data.image
    this.id = data.id
    this.name = data.name
    this.remember_token = data.remember_token
    this.updated_at = data.updated_at

    this._roles = null
  }

  static setUserInCookie (userData) {
    VueCookies.set('user', JSON.stringify(userData), cookieExpireTime, null, null, false, 'Lax')
  }

  static removeUserCookie () {
    VueCookies.remove('user')
  }

  static getUserFromCookie () {
    const userString =  VueCookies.get('user')
    if (userString) {
      return new UserClass(userString)
    }
      return null
  }
}