const state = {
  previousRoute: null
}

const getters = {
  getPreviousRoute (state) {
    return state.previousRoute
  }
}


const mutations = {
  setPreviousRoute (state, route) {
    state.previousRoute = route
  },
}

const actions = {
  updatePreviousRoute (vuexContext, route) {
    vuexContext.commit('setPreviousRoute', route)
  }
}

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions
}
