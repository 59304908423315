import Fuse from "fuse.js"

export default {
  install (Vue) {
    Vue.prototype.$search = function ({ items, searchKey, fuseSearchOptions }) {

      const definedFuseSearchOptions = {
        shouldSort: true,
        threshold: 0.2,
        minMatchCharLength: 1,
        isCaseSensitive: false,
        keys: [
          'name',
        ]
      }


      if (searchKey) {
        const fuseResults = new Fuse(
          items,
          fuseSearchOptions ? fuseSearchOptions : definedFuseSearchOptions
        ).search(searchKey)
        return fuseResults.map(item => item.item)
      }
      return items
    }
  }
}
