
import { featureConstants, useRolesPerProject, userRoleConstants } from '@/constants'

import { CustomErrorClass } from '@/classes/error/CustomErrorClass'



export const featuresPermissionMap = [
  {
    name: featureConstants.LIFE_STYLE,
    projectUserRoles: [
      useRolesPerProject.IS_PROJECT_MANAGER
    ]
  }
]


let access = {
  install (Vue) {
    Vue.prototype.$canAccessFeature = function ({ featureName, userRoles , useRolesPerProject }) {

      if (userRoles) {
        if (userRoles.includes(userRoleConstants.SUPER_ADMIN)) {
          return true
        }

        const foundFeature = featuresPermissionMap.find(
          item => item.name === featureName
        )

        if (foundFeature) {
          return foundFeature.projectUserRoles.some(role => useRolesPerProject[role])
        }

        throw new CustomErrorClass(`Invalid feature name, $canAccessFeature can NOT find ${featureName} --- feature`)
      }

      return false
    }
  }
}

export default access