export function gameStarted (gameName) {
  try {
    if (window.vcreateInterface && window.vcreateInterface.isGameStarted) {
      return window.vcreateInterface.isGameStarted(gameName)
    }
    return false

  } catch (e) {
    console.log(e)
    throw e
  }
}