import Vue from 'vue'
import Vuex from 'vuex'
import auth from '@/store/modules/auth'
import snackbar from '@/store/modules/snackbar'
import organization from '@/store/modules/organization'
import product from '@/store/modules/product'
import project from '@/store/modules/project'
import configuration from '@/store/modules/configuration'
import launcher from '@/store/modules/launcher'
import unity from '@/store/modules/unity'
import router from '@/store/modules/router'
import loading from '@/store/modules/loading'
Vue.use(Vuex)

export default new Vuex.Store({
  modules: {
    auth,
    snackbar,
    organization,
    product,
    project,
    configuration,
    launcher,
    unity,
    router,
    loading
  }
})
