<template>
  <v-app dark>
    <the-default-layout-wrap-app-bar />

    <v-main>
      <the-default-layout-wrap-app-navigation :key="$router.currentRoute.path" />
      <transition mode="out-in" name="scale">
        <v-container :key="$router.currentRoute.path">
          <router-view></router-view>
        </v-container>
      </transition>
    </v-main>
  </v-app>
</template>

<script>
import TheDefaultLayoutWrapAppBar from '@/layouts/TheDefaultLayoutWrapAppBar'
import TheDefaultLayoutWrapAppNavigation from '@/layouts/TheDefaultLayoutWrapAppNavigation'

export default {
  name: 'TheDefaultLayoutWrap',
  components: {
    TheDefaultLayoutWrapAppNavigation,
    TheDefaultLayoutWrapAppBar
  }
}
</script>

<style lang="scss" scoped>
.scale-enter-active,
.scale-leave-active {
  transition: all 0.7s ease;
}

.scale-enter-from,
.scale-leave-to {
  opacity: 0;
  transform: scale(0.7);
}

.container {
  padding: 20px;
}

@media only screen and (max-width: 1024px) {
  .container {
    padding-left: 25px;
    padding-right: 25px;
  }
}

@media only screen and (max-width: 720px) {
  .container {
    padding-left: 20px;
    padding-right: 20px;
  }
}

</style>
