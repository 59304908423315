<template>
  <div>

    <div class="organizations-step-container">
      <organization-list-overview
          :is-list-loading-prop="organizationsLoading"
          :organizations-prop.sync="organizations"
          :selected-organization-prop.sync="selectedOrganization"
          @selectOrganization="selectOrganization"
      />
    </div>


    <div class="d-flex justify-center mt-5">
      <v-btn
        id="organization-continue-button"
        :disabled="isDisabledContinueBtn"
        class="hover-btn-gray"
        color="primary"
        rounded
        width="122px"
        @click.native="continueClick"
      >
        Continue
      </v-btn>
    </div>
  </div>
</template>

<script>
import OrganizationListOverview from '@/components/organization/OrganizationListOverview'

import { enterKeyListenerMixin } from '@/mixins/enterKeyListenerMixin'

import { mapGetters } from 'vuex'
import { actionStates } from '@/constants'

export default {
  name: "OrganizationStepOverviewContainer",

  components: { OrganizationListOverview },

  mixins: [enterKeyListenerMixin],

  data () {
    return {
      actionStateConstants: actionStates
    }
  },

  computed: {
    ...mapGetters(
      {
        organizations: 'organization/getOrganizations',
        currentState: 'organization/getCurrentState',
        selectedOrganization: 'organization/getSelectedOrganization',
        previousRoute: 'router/getPreviousRoute'
      }
    ),

    organizationsLoading () {
      return this.currentState === this.actionStateConstants.LOADING
    },

    isDisabledContinueBtn () {
      return !this.selectedOrganization || this.organizationsLoading
    }
  },

  watch: {
    isDisabledContinueBtn (newVal) {
      this.toggleEnterKeyListener({ isDisabled: newVal, callback: this.continueClick })
    }

  },

  methods: {
    async getAllOrganizations () {
      if (this.previousRoute !== '/skip') {
        await this.$store.dispatch('organization/fetchAllOrganizations')
      }
    },

    removePreviousSelections () {
      this.$store.dispatch('project/removeSelectedProject')
      this.$store.dispatch('product/removeSelectedProduct')
      this.$store.dispatch('configuration/removeSelectedConfiguration')
    },

    async selectOrganization (organization) {
      this.removePreviousSelections()
      await this.$store.dispatch('organization/updateSelectedOrganization', organization)
    },

    async continueClick () {
      await this.$router.push('/product')
    }
  },

  async mounted () {
    await this.getAllOrganizations()

    // Register the Enter key listener
    this.toggleEnterKeyListener({ isDisabled: this.isDisabledContinueBtn, callback: this.continueClick })
  },

  beforeDestroy() {
    if (this.cleanup) {
      this.cleanup()
    }
  }
}
</script>

<style scoped lang="scss">

.organizations-step-container {
  max-height: 500px;
  overflow-y: auto;
}

/* Styles for the scrollbar track */
.organizations-step-container::-webkit-scrollbar-track {
  border-radius: 10px; /* Rounded corners for the track */
  background: #f1f1f1; /* Light grey track background */
}

/* Styles for the scrollbar handle */
.organizations-step-container::-webkit-scrollbar-thumb {
  background: var(--v-primary-base); /* Red scrollbar handle */
  border-radius: 10px; /* Rounded corners for the handle */
}

/* Styles for the scrollbar itself (optional) */
.organizations-step-container::-webkit-scrollbar {
  width: 4px; /* Width of the scrollbar */
}



</style>
