
export const actionStates = {
  INITIAL: "INITIAL",
  LOADING: "LOADING",
  ERRORED: "ERRORED",
  NOT_FOUND: "NOT_FOUND",
  DATA_LOADED: "DATA_LOADED"
}


export const userRoleConstants = {
  SUPER_ADMIN: 'super-admin',
  PROJECT_MANAGER: 'project-manager',
  ARCHITECT: 'architect',
  CONFIGURATOR: 'configurator'
};

export const featureConstants = {
  LIFE_STYLE: 'life-style',
}

export const useRolesPerProject =  {
  IS_CONFIGURATOR: 'is_configurator',
  IS_ARCHITECT: 'is_architect',
  IS_PROJECT_MANAGER: 'is_project_manager'
}

export const cookieExpireTime = '1M'


