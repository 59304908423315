<template>
  <div>
    <v-container class="list-container">
      <list-overview
        :can-create=true
        :data-source="organizationsProp"
        :is-list-loading-prop="isListLoadingProp"
        :selectable="true"
        list-type="organization"
      >
        <template #entity-card="{ entity, selectable }">
          <list-item-common
            :class="highlightSelectedOrganizationClass(entity.id)"
            :entity="entity"
            :selectable="selectable"
            class="cursor-pointer"
            @click.native="$emit('selectOrganization', entity)"
          />
        </template>
      </list-overview>
    </v-container>
  </div>
</template>

<script>
import ListOverview from '@/components/list/ListOverview'
import ListItemCommon from '@/components/list/ListItemCommon'

export default {
  name: "OrganizationListOverview",
  components: { ListItemCommon, ListOverview },
  props: {
    organizationsProp: {
      type: Array,
      required: true
    },
    isListLoadingProp: {
      type: Boolean,
      required: true
    },
    selectedOrganizationProp: {
      type: Object,
      default: null
    }
  },

  data () {
    return {
      organizations: this.organizationsProp
    }
  },

  computed: {},

  methods: {
    highlightSelectedOrganizationClass (entityId) {
      if (this.selectedOrganizationProp && this.selectedOrganizationProp.id) {
        return (entityId === this.selectedOrganizationProp.id) ? 'active-item' : 'inactive-item'
      }
    }
  }

}
</script>

<style lang="scss" scoped>
.active-item {
  border: 2px solid;
  border-color: var(--v-primary-base);
}

.inactive-item {
  border: 1px solid #707070;
  opacity: 0.2;
}

.list-container {
  width: 85%;
  @media only screen and (max-width: 1900px) {
    width: 100%;
  }
}
</style>
