<template>
  <v-row>
    <v-col v-if="isListLoadingProp || !hasDataSource">
      <v-row align="center" justify="center">
        <v-col v-for="i in [1, 2, 3]" :key="i + 'skeleton-loader'" v-bind="cols">
          <v-skeleton-loader height="205" type="card" :boilerplate="!hasDataSource" />
        </v-col>
      </v-row>
    </v-col>

    <v-col v-else-if="isListLoadingProp === false" cols="12">
      <v-data-iterator
          v-if="listedItems.length"
          :disable-pagination="true"
          :items="listedItems"
          :show-select="false"
          :single-expand="true"
          class="flex-grow-1"
          hide-default-footer
      >
        <template #default="props">
          <v-row align="center" justify="center">
            <v-col
                v-for="(item, index) in props.items"
                :key="item.id + index"
                :class="listType === 'project' ? 'pa-3' : ''"
                v-bind="cols"
            >
              <slot
                  :entity="item"
                  :selectable="selectable"
                  name="entity-card"
              />
            </v-col>
          </v-row>
        </template>
      </v-data-iterator>

      <v-row v-else-if="listedItems.length === 0">
        <v-col v-if="canCreate" v-bind="cols">
          <slot name="create-card" />
        </v-col>

        <v-col v-else-if="canCreate === false" class="text--secondary" cols="12">
          <p>There are no {{ listType.replace('-', ' ') }}s available.</p>
        </v-col>
      </v-row>
    </v-col>
  </v-row>
</template>

<script>

export default {
  name: 'ListOverview',
  props: {
    title: {
      type: String,
      required: false,
      default: ''
    },

    listType: {
      type: String,
      required: true
    },

    dataSource: {
      type: Array,
      required: false,
      default () {
        return []
      }
    },

    selectable: {
      type: Boolean,
      required: false,
      default: false
    },

    canCreate: {
      type: Boolean,
      required: false,
      default: false
    },

    onlyCreate: {
      type: Boolean,
      required: false,
      default: false
    },

    isListLoadingProp: {
      type: Boolean,
      default: false
    },
    colsProp: {
      type: Object,
      default: () => {
        return {
          cols: 12,
          sm: 6,
          md: 4,
          lg: 3,
          xl: 3
        }
      }

    }
  },

  data () {
    return {
      listedItems: this.dataSource,
      cols: this.colsProp
    }
  },

  computed: {
    hasDataSource () {
      return this.listedItems && this.listedItems.length > 0;

    }
  },

  watch: {
    dataSource () {
      this.listedItems = this.dataSource
    }
  }

}
</script>
